import { memo } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

interface ISimpleTextFieldProps {
  name: string;
  multiline?: boolean;
  isDisabled?: boolean;
  rows?: number;
  type: string;
  placeholder?: string;
}

const SimpleTextField = ({
  type,
  name,
  multiline,
  rows,
  placeholder,
  isDisabled,
}: ISimpleTextFieldProps) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      {...field}
      id={name}
      type={type}
      size="small"
      fullWidth
      autoComplete="off"
      disabled={isDisabled}
      placeholder={placeholder}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      multiline={multiline}
      rows={multiline ? rows : 0}
      inputProps={{
        maxLength: 400,
      }}
    />
  );
};

export default memo(SimpleTextField);
