interface NewsData {
  title: string;
  image: string;
  description: string;
}

export const extractNewsData = (htmlContent: string): NewsData => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  let title = "";
  let image = "";
  let description = "";

  const titleElement = doc.querySelector("h1");
  if (titleElement) {
    title = titleElement.textContent || "";
  }

  const imageElement = doc.querySelector("img");
  if (imageElement) {
    image = imageElement.getAttribute("src") || "";
  }

  const textElement = doc.querySelector("p");
  if (textElement) {
    let nextSibling = textElement.nextElementSibling;
    while (nextSibling) {
      if (nextSibling.tagName.toLowerCase() === "p") {
        description = nextSibling.innerHTML.trim();
        break;
      }
      nextSibling = nextSibling.nextElementSibling;
    }
  }

  return { title, image, description };
};
