import { createSlice } from "@reduxjs/toolkit";
import { INewsState } from "./types";
import {
  fetchNextPageOfNews,
  fetchNews,
  submitNews,
  deleteNewsById,
  fetchCurrentNews,
  updateNews,
} from "./operation";

const initialState: INewsState = {
  loadingNews: false,
  loadingNextPage: false,
  loadingCurrent: false,
  error: null,
  newsData: null,
  сurrentNews: null,
  lastVisible: null,
  hasMore: true,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state: INewsState, { payload }) => {
      return { ...state, newsData: { ...state.newsData, ...payload } };
    },

    setCurrent: (state: INewsState, { payload }) => {
      return { ...state, сurrentNews: payload };
    },

    deleteNews: (state: INewsState, { payload }) => {
      return {
        ...state,
        newsData: { ...state.newsData, [payload]: null },
      };
    },

    setLastVisible: (state: INewsState, { payload }) => {
      return { ...state, lastVisible: payload };
    },

    setHasMore: (state: INewsState, { payload }) => {
      return { ...state, hasMore: payload };
    },

    revokeCurrent: (state: INewsState) => {
      return {
        ...state,
        сurrentNews: null,
      };
    },

    revoke: (state: INewsState) => {
      return {
        loadingNews: false,
        loadingNextPage: false,
        loadingCurrent: false,
        error: null,
        newsData: null,
        сurrentNews: null,
        lastVisible: null,
        hasMore: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state: INewsState, { payload }) => {
        return { ...state, loadingNews: true };
      })
      .addCase(fetchNews.fulfilled, (state: INewsState, { payload }) => {
        return { ...state, loadingNews: false };
      })
      .addCase(fetchNews.rejected, (state: INewsState, { payload }) => {
        return { ...state, loadingNews: false, error: payload as string };
      })
      .addCase(submitNews.pending, (state: INewsState, { payload }) => {
        return { ...state, loadingNews: true };
      })
      .addCase(submitNews.fulfilled, (state: INewsState, { payload }) => {
        return {
          ...state,
          loadingNews: false,
        };
      })
      .addCase(submitNews.rejected, (state: INewsState, { payload }) => {
        return { ...state, loadingNews: false, error: payload as string };
      })
      .addCase(deleteNewsById.pending, (state: INewsState, { payload }) => {
        return { ...state };
      })
      .addCase(deleteNewsById.fulfilled, (state: INewsState, { payload }) => {
        return { ...state };
      })
      .addCase(deleteNewsById.rejected, (state: INewsState, { payload }) => {
        return { ...state, error: payload as string };
      })

      .addCase(
        fetchNextPageOfNews.pending,
        (state: INewsState, { payload }) => {
          return { ...state, loadingNextPage: true };
        }
      )
      .addCase(
        fetchNextPageOfNews.fulfilled,
        (state: INewsState, { payload }) => {
          return {
            ...state,
            loadingNextPage: false,
          };
        }
      )
      .addCase(
        fetchNextPageOfNews.rejected,
        (state: INewsState, { payload }) => {
          return { ...state, loadingNextPage: false, error: payload as string };
        }
      )

      .addCase(fetchCurrentNews.pending, (state: INewsState, { payload }) => {
        return { ...state, loadingCurrent: true };
      })
      .addCase(fetchCurrentNews.rejected, (state: INewsState, { payload }) => {
        return { ...state, loadingCurrent: false };
      })
      .addCase(fetchCurrentNews.fulfilled, (state: INewsState, { payload }) => {
        return { ...state, loadingCurrent: false };
      })
      .addCase(updateNews.pending, (state: INewsState, { payload }) => {
        return { ...state };
      })
      .addCase(updateNews.rejected, (state: INewsState, { payload }) => {
        return { ...state, сurrentNews: null, error: payload as string };
      })
      .addCase(updateNews.fulfilled, (state: INewsState, { payload }) => {
        return { ...state, сurrentNews: null };
      });
  },
});
export const {
  setNews,
  setLastVisible,
  setHasMore,
  deleteNews,
  revoke,
  setCurrent,
  revokeCurrent,
} = newsSlice.actions;

export const newsReducer = newsSlice.reducer;
