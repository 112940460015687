import { SelectChangeEvent, Typography, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import { langData } from "../constans";
import { SelectItemBox, StyledSelect } from "../style";
import { useLocation, useNavigate } from "react-router-dom";

interface ISelectLangProps {
  blackColor?: boolean;
}

export default function SelectLang({ blackColor }: ISelectLangProps) {
  const [language, setLanguage] = useState(
    () => localStorage.getItem("i18nextLng") || "ua"
  );
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const curPath = location.pathname;

  const handleChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
    const newPath = `/${newLang}${curPath.slice(language.length + 1)}`;
    navigate(newPath, { replace: true });
  };

  return (
    <StyledSelect
      disableUnderline
      variant="standard"
      props={{ black: blackColor }}
      //@ts-ignore
      onChange={handleChange}
      value={language}
    >
      {langData.map(({ label, width, height, src }) => (
        <MenuItem value={label} key={nanoid()}>
          <SelectItemBox>
            <img src={src} alt={label} width={width} height={height} />
            <Typography>{label}</Typography>
          </SelectItemBox>
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
