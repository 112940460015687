import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const FooterWrapper = styled(Box)(({ theme }) => ({
  flexShrink: "0",
  background: "#ffff",
}));

export const FooterContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

export const ColumnListBox = styled(List)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  alignItems: "flex-start",
}));

export const RowListBox = styled(Grid)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: spacing(1),
  alignItems: "flex-start",
}));

interface IListItemLinkProps {
  props?: { isOnPathname: boolean };
}

export const ListItemLink = styled(ListItem)<IListItemLinkProps>(
  ({ theme: { spacing }, props }) => ({
    a: {
      color: props?.isOnPathname ? "#005094" : PrimeTexrColor,
      whiteSpace: "nowrap",
      fontSize: 14,
      fontWeight: props?.isOnPathname ? 500 : 400,

      "&:hover, &:focus, &:active": {
        textDecoration: "underline",
      },
    },
  })
);

interface ILinkBoxProps {
  props?: { isOnPathname: boolean };
}

export const LinkBox = styled(Box)<ILinkBoxProps>(
  ({ theme: { spacing }, props }) => ({
    a: {
      color: props?.isOnPathname ? "#005094" : PrimeTexrColor,
      whiteSpace: "nowrap",
      fontSize: 14,
      fontWeight: props?.isOnPathname ? 500 : 400,

      "&:hover, &:focus, &:active": {
        textDecoration: "underline",
      },
    },
  })
);

export const ListTitle = styled(Typography)(({ theme }) => ({
  color: "#a7a7a7",
  fontWeight: 400,
  fontSize: 16,
  marginBottom: 4,
}));

export const ContactsText = styled(Typography)(({ theme }) => ({
  color: "#434542",
  fontWeight: 400,
  whiteSpace: "nowrap",
  fontSize: 14,
}));

export const CopyrightBox = styled(Box)(({ theme: { spacing } }) => ({
  textAlign: "left",
  marginTop: spacing(1),
  marginBottom: spacing(1),
  paddingTop: spacing(1),
  paddingBottom: spacing(1),
}));

export const CopyrightText = styled(Typography)({
  color: "#a7a7a7",
  fontWeight: 400,
  fontSize: 14,
});

export const FacebookGrey = styled(FacebookIcon)(({ theme }) => ({
  color: "#a7a7a7",
}));

export const InstagramGrey = styled(InstagramIcon)(({ theme }) => ({
  color: "#a7a7a7",
}));
