import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userReducer } from "features/user/slice";
import { authReducer } from "features/auth/slice";
import { IUserState } from "features/user/types";
import { IAuth } from "features/auth/types";
import { reviewsReducer } from "features/reviews/slice";
import { IReviewsState } from "features/reviews/types";
import { IPointsState } from "features/points/types";
import { pointsReducer } from "features/points/slice";
import { searchFormReducer } from "features/searchform/slice";
import { ISearchFormState } from "features/searchform/types";
import { busesReducer } from "features/buses/slice";
import { IBusesState } from "features/buses/types";
import { ITickets } from "features/tickets/types";
import { ticketsReducer } from "features/tickets/slice";
import { checkoutReducer } from "features/checkout/slice";
import { ICheckoutState } from "features/checkout/types";
import { ordersReducer } from "features/orders/slice";
import { IOrdersState } from "features/orders/types";
import { modalReducer } from "features/modal/slice";
import { IModalState } from "features/modal/types";
import { INewsState } from "features/news/types";
import { newsReducer } from "features/news/slice";

import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { reportsReducer } from "features/reports/slice";
import { IReportsState } from "features/reports/types";

const persistConfig = {
  key: "persist",
  storage,
  whitelist: ["tickets", "searchform", "payment", "user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  reviews: reviewsReducer,
  points: pointsReducer,
  searchform: searchFormReducer,
  buses: busesReducer,
  tickets: ticketsReducer,
  checkout: checkoutReducer,
  orders: ordersReducer,
  modal: modalReducer,
  news: newsReducer,
  reports: reportsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export interface IStore {
  auth: IAuth;
  user: IUserState;
  reviews: IReviewsState;
  points: IPointsState;
  searchform: ISearchFormState;
  buses: IBusesState;
  tickets: ITickets;
  checkout: ICheckoutState;
  orders: IOrdersState;
  modal: IModalState;
  news: INewsState;
  reports: IReportsState;
}
