import React, { memo, Ref, useMemo, ReactElement } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  to: any;
  replace?: boolean;
  innerRef?: Ref<HTMLAnchorElement>;
  children: ReactElement | string;
  rel?: string;
  target?: string;
  className?: string;
  isGlobal?: boolean;
  onClick?: () => void;
}

function LinkComponent({
  to,
  replace,
  innerRef,
  children,
  rel,
  target,
  isGlobal = false,
  onClick = () => {},
}: IProps): ReactElement {
  const { i18n } = useTranslation();
  const lngTo = useMemo(() => {
    const lng = `/${i18n.language}`;
    return typeof to === "string"
      ? `${lng}${to}`
      : { ...to, pathname: `${lng}${to.pathname}` };
  }, [to, i18n.language]);

  return isGlobal ? (
    <a
      // @ts-ignore
      href={to}
      rel={rel}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      to={lngTo}
      replace={replace}
      ref={innerRef}
      rel={rel}
      target={target}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

export default memo(LinkComponent);
