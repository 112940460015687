import { Autocomplete, Box, TextField } from "@mui/material";
import { usePoints } from "features/points/usePoints";
import { useField } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { blockedIds } from "./helpers";
import CircularLoading from "components/CircularLoading";

interface ICustomAutocompleteProps {
  name: string;
  label: string;
  backInput?: boolean;
}

function CityPicker({ name, label }: ICustomAutocompleteProps) {
  const [field, meta, helpers] = useField(name);
  const { poitns, isLoading } = usePoints();

  const { t } = useTranslation();

  const filteredPoints =
    poitns?.filter((point) => !blockedIds.includes(point.id)) || [];

  const sortedPoints = filteredPoints.sort((a, b) => {
    const nameA = t(`cityKey-${a.id}`).toLowerCase();
    const nameB = t(`cityKey-${b.id}`).toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <Autocomplete
      {...field}
      id={name}
      loading={isLoading}
      popupIcon={false}
      clearIcon={false}
      value={field.value}
      loadingText={
        <Box sx={{ p: 0.5, textAlign: "center" }}>
          <CircularLoading size={24} />
        </Box>
      }
      noOptionsText={t("not_found")}
      includeInputInList
      onChange={(event, newValue) => {
        helpers.setValue(newValue);
      }}
      options={Array.from(
        new Set(sortedPoints.map((e) => t(`cityKey-${e.id}`)))
      )}
      isOptionEqualToValue={(option, value) =>
        t(`cityKey-${option}`) === t(`cityKey-${field.value}`)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          size="small"
          label={label}
          variant="outlined"
          name={name}
          error={meta.touched && Boolean(meta.error)}
        />
      )}
    />
  );
}

export default memo(CityPicker);
