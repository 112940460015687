import {
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { storage } from "services/firebase";

export async function parseAndUploadImages(news: string): Promise<string> {
  const imgTagRegex =
    /<img[^>]+src="(data:image\/[^;]+;base64[^"]+|https:\/\/firebasestorage\.googleapis\.com[^"]+)"[^>]*>/g;
  const base64SrcRegex = /src="(data:image\/[^;]+;base64[^"]+)"/;
  const urlSrcRegex = /src="(https:\/\/firebasestorage\.googleapis\.com[^"]+)"/;

  let match;
  const uploadPromises: Promise<{ original: string; uploaded: string }>[] = [];

  while ((match = imgTagRegex.exec(news)) !== null) {
    const imgTag = match[0];
    const srcMatch = imgTag.match(base64SrcRegex) || imgTag.match(urlSrcRegex);
    if (srcMatch && srcMatch[1]) {
      const src = srcMatch[1];
      if (src.startsWith("data:image/")) {
        const base64String = src;
        const storageRef = ref(storage, `images/${nanoid()}`);
        const uploadPromise = uploadString(
          storageRef,
          base64String,
          "data_url"
        ).then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          return { original: imgTag, uploaded: `<img src="${downloadURL}" />` };
        });
        uploadPromises.push(uploadPromise);
      } else if (src.startsWith("https://firebasestorage.googleapis.com")) {
        // Если изображение уже в Firebase Storage, не изменяем его
        uploadPromises.push(
          Promise.resolve({ original: imgTag, uploaded: imgTag })
        );
      }
    }
  }

  const uploadedImages = await Promise.all(uploadPromises);
  let updatedNews = news;

  uploadedImages.forEach(({ original, uploaded }) => {
    updatedNews = updatedNews.replace(original, uploaded);
  });

  return updatedNews;
}

export async function deleteUnusedImages(
  oldNews: string,
  newNews: string
): Promise<void> {
  function extractImageUrls(text: string): string[] {
    const urlSrcRegex =
      /<img[^>]+src="https?:\/\/[^"]+\/images%2F([^"]+)\?alt=media[^"]*"[^>]*>/g;
    const urls = [];
    let match;
    while ((match = urlSrcRegex.exec(text)) !== null) {
      urls.push(match[1]);
    }
    return urls;
  }

  const oldImageUrls = extractImageUrls(oldNews);
  const newImageUrls = extractImageUrls(newNews);

  const deletePromises = oldImageUrls.map(async (url) => {
    try {
      // Проверяем, есть ли изображение в новой версии новости
      if (!newImageUrls.includes(url)) {
        const filename = url.substring(url.lastIndexOf("/") + 1);
        const storageRef = ref(storage, `images/${filename}`);

        // Проверяем существование объекта перед удалением
        const snapshot = await getDownloadURL(storageRef);
        if (snapshot) {
          await deleteObject(storageRef);
        }
      }
    } catch (error) {
      console.error("Ошибка при удалении изображения:", error);
    }
  });

  await Promise.all(deletePromises);
}

export async function deleteNewsImages(newsText: string): Promise<string> {
  try {
    const imgTagRegex =
      /<img[^>]+src="https?:\/\/[^"]+\/images%2F([^"]+)\?alt=media[^"]*"[^>]*>/g;
    let match;
    let updatedNewsText = newsText;

    while ((match = imgTagRegex.exec(newsText)) !== null) {
      const imageId = match[1];
      const storageRef = ref(storage, `images/${imageId}`);
      await deleteObject(storageRef);

      updatedNewsText = updatedNewsText.replace(match[0], "");
    }

    return updatedNewsText;
  } catch (error) {
    console.error("Помилка при видаленні зображень:", error);
    throw error;
  }
}
