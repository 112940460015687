import Link from "components/Link";

interface ISocialLinkItemProps {
  href: string;
  icon: JSX.Element;
}

export default function SocialLinkItem({ href, icon }: ISocialLinkItemProps) {
  return (
    <Link isGlobal to={href} target="_blank">
      {icon}
    </Link>
  );
}
