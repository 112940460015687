import { CartMetaContainer, CartMetaIcon, CartMetaText } from "./style";

interface ICartMetaProps {
  date: string;
}

export default function CartMeta({ date }: ICartMetaProps) {
  return (
    <CartMetaContainer>
      <CartMetaIcon />
      <CartMetaText>{date}</CartMetaText>
    </CartMetaContainer>
  );
}
