import { IconButton, Box, Divider, ListItem } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import { nanoid } from "nanoid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "features/auth/useAuth";
import { contactData, pagesRoutes } from "./constans";
import SinginItems from "./components/SinginItems";
import NotSinginItems from "./components/NotSinginItems";
import BurgerMenuAccordion from "./components/BurgerMenuAccordion";
import LogoLink from "./components/LogoLink";
import ContactsItem from "./components/ContactsItem";
import {
  BurgeLangText,
  BurgeLangWrapper,
  BurgeListWrapper,
  BurgerHeaderWrapper,
  LargeDrawer,
} from "./style";
import NavigateLink from "./components/NavigateLink";
import SelectLang from "./components/SelectLang";
import { useTranslation } from "react-i18next";

interface IBurgerMenu {
  open: boolean;
  toggleDrawer: () => void;
}

function BurgerMenu({ open, toggleDrawer }: IBurgerMenu) {
  const { isSignedIn } = useAuth();
  const { t } = useTranslation();
  return (
    <LargeDrawer anchor="right" open={open} onClose={() => toggleDrawer()}>
      <Box>
        <BurgerHeaderWrapper>
          <Box onClick={() => toggleDrawer()}>
            <LogoLink burgerLogo />
          </Box>

          <IconButton onClick={() => toggleDrawer()}>
            <CloseIcon />
          </IconButton>
        </BurgerHeaderWrapper>

        <Divider />
        <BurgeListWrapper>
          <ListItem>
            <BurgerMenuAccordion title={t("support")} icon={<ExpandMoreIcon />}>
              {contactData.map((el) => (
                <ContactsItem
                  contact={el}
                  handleCloseUserMenu={toggleDrawer}
                  key={nanoid()}
                />
              ))}
            </BurgerMenuAccordion>
          </ListItem>
          <ListItem>
            <BurgerMenuAccordion
              title={t("personalCabinet")}
              icon={<PersonIcon />}
            >
              {isSignedIn ? (
                <SinginItems handleClose={toggleDrawer} />
              ) : (
                <NotSinginItems handleClose={toggleDrawer} />
              )}
            </BurgerMenuAccordion>
          </ListItem>
        </BurgeListWrapper>
        <Divider />
        <BurgeListWrapper>
          {pagesRoutes.map(({ title, link }) => (
            <NavigateLink
              title={title}
              href={link}
              onClick={toggleDrawer}
              key={nanoid()}
            />
          ))}
        </BurgeListWrapper>
        <Divider />
        <BurgeLangWrapper>
          <BurgeLangText>{t("language")}</BurgeLangText>
          <SelectLang blackColor />
        </BurgeLangWrapper>
      </Box>
    </LargeDrawer>
  );
}

export default BurgerMenu;
