export type TErrorStore = string | null;
export type TErrorCode = string;
export type TMessage = string;
export interface IHandledError {
  code: string;
  message: string;
  description: string | null;
}

interface IFirebaseError {
  code: TErrorCode;
  message: TMessage;
}

type TObjectError = IFirebaseError;

export type TError = TObjectError | undefined | null | any;

const platform = "web";

function log(code: TErrorCode, message: TMessage, error: TError): void {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(`__ERROR ${platform}__:`, `[${code}]`, message, "\n__ORIGIN__:", `[${error?.code}]`, error?.message);
  } else {
    // eslint-disable-next-line no-console
    console.error(`Error ${platform}:`, `[${code}]`, message);
  }
}

export default function handleError(code: TErrorCode, error?: TError): IHandledError {
  const normalizedCode = error ? `errors.${code}.${error?.code}` : `errors.${code}`;
  const message = normalizedCode;

  const isTest = true;
  if (isTest) {
    log(normalizedCode, message, error);
  }

  return {
    code: normalizedCode,
    message,
    description: error?.message || null,
  };
}
