import { memo, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "services/firebase";

import { setSigninFirebase } from "features/auth/operation";
import { setNotSignedIn } from "features/auth/slice";
import { useAppDispatch } from "store/hooks";
function User() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        dispatch(setSigninFirebase(user));
      } else {
        dispatch(setNotSignedIn());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default memo(User);
