import { StyledIconButton } from "./style";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

export default function SwapBtn() {
  return (
    <StyledIconButton size="large" disableRipple>
      <SwapHorizIcon sx={{ color: "#0088d1" }} fontSize="large" />
    </StyledIconButton>
  );
}
