import { createSlice } from "@reduxjs/toolkit";
import { IOrdersState } from "./types";
import {
  deleteOrderById,
  fetchNextPageOfOrders,
  fetchOrders,
  fetchOrdersByPaymentId,
  fetchOrdersByPhoneNumber,
} from "./operation";

const initialState: IOrdersState = {
  loadingOrders: false,
  loadingNextPage: false,
  error: null,
  orders: null,
  lastVisible: null,
  orderbypaymentid: null,
  hasMore: true,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrder: (state: IOrdersState, { payload }) => {
      return { ...state, orders: { ...state.orders, ...payload } };
    },
    setLastVisible: (state: IOrdersState, { payload }) => {
      return { ...state, lastVisible: payload };
    },

    setOrderByid: (state: IOrdersState, { payload }) => {
      return { ...state, orderbypaymentid: payload };
    },

    setHasMore: (state: IOrdersState, { payload }) => {
      return { ...state, hasMore: payload };
    },

    deleteOrder: (state: IOrdersState, { payload }) => {
      return {
        ...state,
        orders: { ...state.orders, [payload]: null },
      };
    },

    revoke: (state: IOrdersState) => {
      return {
        loadingOrders: false,
        loadingNextPage: false,
        error: null,
        orders: null,
        lastVisible: null,
        hasMore: true,
        orderbypaymentid: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state: IOrdersState, { payload }) => {
        return { ...state, loadingOrders: true };
      })
      .addCase(fetchOrders.fulfilled, (state: IOrdersState, { payload }) => {
        return { ...state, loadingOrders: false };
      })
      .addCase(fetchOrders.rejected, (state: IOrdersState, { payload }) => {
        return {
          ...state,
          loadingOrders: false,
          error: payload as string,
        };
      })
      .addCase(
        fetchNextPageOfOrders.pending,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingNextPage: true };
        }
      )
      .addCase(
        fetchNextPageOfOrders.fulfilled,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingNextPage: false };
        }
      )
      .addCase(
        fetchNextPageOfOrders.rejected,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingNextPage: false, error: payload as string };
        }
      )
      .addCase(
        fetchOrdersByPhoneNumber.pending,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: true };
        }
      )
      .addCase(
        fetchOrdersByPhoneNumber.fulfilled,
        (state: IOrdersState, { payload }) => {
          return { ...state, orders: { ...payload }, loadingOrders: false };
        }
      )
      .addCase(
        fetchOrdersByPhoneNumber.rejected,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: false, error: payload as string };
        }
      )
      .addCase(
        deleteOrderById.pending,
        (state: IOrdersState, { payload }) => {
          return { ...state,  };
        }
      )
      .addCase(
        deleteOrderById.fulfilled,
        (state: IOrdersState, { payload }) => {
          return { ...state, };
        }
      )
      .addCase(
        deleteOrderById.rejected,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: false, error: payload as string };
        }
      )
      .addCase(
        fetchOrdersByPaymentId.pending,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: true };
        }
      )
      .addCase(
        fetchOrdersByPaymentId.fulfilled,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: false };
        }
      )
      .addCase(
        fetchOrdersByPaymentId.rejected,
        (state: IOrdersState, { payload }) => {
          return { ...state, loadingOrders: false, error: payload as string };
        }
      );
  },
});

export const { setOrder, setHasMore, setLastVisible, revoke, setOrderByid,deleteOrder } =
  ordersSlice.actions;

export const ordersReducer = ordersSlice.reducer;
