import { styled } from "@mui/material/styles";
import { deepOrange, grey, blue, orange } from "@mui/material/colors";
import { Button, TextField, Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginBottom: spacing(1),
  marginTop: spacing(6),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginBottom: spacing(2),
    marginTop: spacing(10),
  },
}));
export const GreyKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)({
  color: grey[500],
});

export const BuyTicketsContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const BuyTicketsTitleWrapper = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(24),

  "@media (min-width:600px)": {
    fontSize: theme.typography.pxToRem(28),
  },
  color: grey[100],
  marginBottom: theme.spacing(8),
  textAlign: "center",
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  alignItems: "center",
  padding: theme.spacing(1),
  backgroundColor: "white",
  borderRadius: theme.spacing(1),
  overflow: "hidden",

  ":hover": {
    backgroundColor: grey[200],
    borderRadius: theme.spacing(1),
    cursor: "pointer",
  },
}));

export const ListItemText = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(0.5),
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 400,

  span: {
    fontWeight: 300,
    color: grey[600],
  },
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  color: `rgb(40, 79, 87)`,
  fontSize: 16,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 19,
  },
}));

export const InfoAccentText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: deepOrange[400],
  fontWeight: 600,
  "@media (min-width:600px)": {
    fontSize: 22,
  },
}));

export const SearchformWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: "white",
  borderRadius: theme.spacing(1),
}));

export const RelativAutocompleteWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const SwapWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  padding: 4,
  right: 0,
  zIndex: 1000,
  color: grey[500],
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 41.44,
  },
}));

export const StyledAutocompliteInput = styled(TextField)(({ theme }) => ({
  "& .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
    {
      paddingTop: "14px",
      paddingBottom: "0px",
      paddingLeft: "6px",
    },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "green",
    border: `1px solid ${grey[400]}`,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red",
    border: `1px solid ${blue[400]}`,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // color: blue[400],
  },
  "& .MuiOutlinedInput-input": {
    // color: "green",
  },
  "&:hover .MuiOutlinedInput-input": {
    // color: "red",
  },

  //MuiInputLabel-root
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    // color: blue[400],
  },
  "& .MuiInputLabel-outlined": {},

  "& .MuiInputLabel-root .MuiInputLabel-outlined ": {
    color: "red",
  },
  "&:hover .MuiInputLabel-outlined": {
    color: orange[400],
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: orange[400],
  },
}));

export const SDatePicker = styled(DatePicker)({
  "& .MuiFilledInput-root": {
    background: "red",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "green",
    border: `1px solid ${grey[400]}`,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    // borderColor: "red",
    border: `1px solid ${blue[400]}`,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // color: blue[400],
  },
  "& .MuiOutlinedInput-input": {
    // color: "green",
  },
  "&:hover .MuiOutlinedInput-input": {
    // color: "red",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    // color: blue[400],
  },
  "& .MuiInputLabel-outlined": {
    color: grey[500],
  },
  "&:hover .MuiInputLabel-outlined": {
    color: blue[400],
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: blue[400],
  },
});

export const ColorWrapper = styled(Box)({
  background:
    "linear-gradient(180deg, rgba(0,80,148,1) 0%, rgba(58,123,217,1) 100%)",
});
