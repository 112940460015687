import { IUseTickets } from "./types";
import {
  clearTicket,
  clearTickets,
  setTicket,
  setTicketsFrom,
  setTicketsTo,
} from "./slice";
import { getTicketsFrom, getTicketsTo, selectSelectTicket } from "./selector";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";

export const useTickets = (): IUseTickets => {
  const ticketsTo = useSelector(getTicketsTo);
  const ticketsFrom = useSelector(getTicketsFrom);
  const selectTicket = useSelector(selectSelectTicket);

  const dispatch = useAppDispatch();

  return {
    ticketsTo,
    ticketsFrom,
    selectTicket,

    setTicketsTo: (data) => {
      dispatch(setTicketsTo(data));
    },
    setTicketsFrom: (data) => {
      dispatch(setTicketsFrom(data));
    },
    clearTickets: () => {
      dispatch(clearTickets());
    },
    clearTicket: () => {
      dispatch(clearTicket());
    },
    setTicket: (data) => {
      dispatch(setTicket(data));
    },
  };
};
