import Link from "components/Link";
import logo from "../images/logo.png";
import logo2 from "../images/logo2.png";

interface ILogoLinkProps {
  burgerLogo?: boolean;
}

export default function LogoLink({ burgerLogo }: ILogoLinkProps) {
  return (
    <Link to={"/"} replace>
      <img src={burgerLogo ? logo2 : logo} width={133} height={33} alt="Logo" />
    </Link>
  );
}
