import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import {
  setHasMore,
  setOrder,
  setLastVisible,
  setOrderByid,
  deleteOrder,
} from "./slice";
import { IOrder, ITticket } from "./types";
import { IStore } from "store/store";
import { declinePayment } from "services/functions";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { user } = getState() as IStore;

    try {
      const ordersRoleQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "payments"), limit(6))
          : query(
              collection(firestore, "payments"),
              where("userId", "==", user.data?.id),
              limit(6)
            );

      const countOrdersQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "payments"))
          : query(
              collection(firestore, "payments"),
              where("userId", "==", user.data?.id)
            );

      const ordersRoleSnapshot = await getDocs(ordersRoleQuery);

      if (ordersRoleSnapshot.empty) {
        dispatch(setHasMore(false));
        return rejectWithValue("Empty");
      }

      const snapshotCountOrders = await getCountFromServer(countOrdersQuery);

      const ordersCount = snapshotCountOrders.data().count;

      const ordersData: IOrder[] = ordersRoleSnapshot.docs.map((doc) => {
        return { ...(doc.data() as IOrder), paymentId: doc.id };
      });

      const paymentIds = ordersData.map((order) => order.paymentId);

      const ticketsQuery = query(
        collection(firestore, "tickets"),
        where("paymentId", "in", paymentIds)
      );

      const ticketsSnapshot = await getDocs(ticketsQuery);

      const ticketsData: ITticket[] = ticketsSnapshot.docs.map(
        (doc) => doc.data() as ITticket
      );

      const normalizeOrders = ordersData.reduce((acc, order) => {
        const ticketsForOrder = ticketsData.filter(
          (ticket) => ticket.paymentId === order.paymentId
        );
        return {
          ...acc,
          [order.paymentId]: { ...order, tickets: ticketsForOrder },
        };
      }, {});

      dispatch(setOrder(normalizeOrders));

      const last = ordersRoleSnapshot.docs[ordersRoleSnapshot.docs.length - 1];

      const hasMore = ordersCount > ordersRoleSnapshot.size;
      dispatch(setLastVisible(hasMore ? last : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchNextPageOfOrders = createAsyncThunk(
  "orders/fetchNextPageOfOrders",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { user, orders } = getState() as IStore;

    try {
      const ordersRoleQuery =
        user.data?.role === "admin"
          ? query(
              collection(firestore, "payments"),
              startAfter(orders.lastVisible),
              limit(6)
            )
          : query(
              collection(firestore, "payments"),
              where("userId", "==", user.data?.id),
              startAfter(orders.lastVisible),
              limit(6)
            );

      const countOrdersQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "payments"))
          : query(
              collection(firestore, "payments"),
              where("userId", "==", user.data?.id)
            );

      const snapshotCountOrders = await getCountFromServer(countOrdersQuery);
      const ordersCount = snapshotCountOrders.data().count;

      const ordersRoleSnapshot = await getDocs(ordersRoleQuery);

      const ordersData: IOrder[] = ordersRoleSnapshot.docs.map((doc) => {
        return { ...(doc.data() as IOrder), paymentId: doc.id };
      });

      const paymentIds = ordersData.map((order) => order.paymentId);

      const ticketsQuery = query(
        collection(firestore, "tickets"),
        where("paymentId", "in", paymentIds)
      );

      const ticketsSnapshot = await getDocs(ticketsQuery);

      const ticketsData: ITticket[] = ticketsSnapshot.docs.map(
        (doc) => doc.data() as ITticket
      );

      const normalizeOrders = ordersData.reduce((acc, order) => {
        const ticketsForOrder = ticketsData.filter(
          (ticket) => ticket.paymentId === order.paymentId
        );
        return {
          ...acc,
          [order.paymentId]: { ...order, tickets: ticketsForOrder },
        };
      }, {});

      const last = ordersRoleSnapshot.docs[ordersRoleSnapshot.docs.length - 1];
      const hasMore =
        ordersCount >
        ordersRoleSnapshot.size + Object.keys(orders.orders || {}).length;

      dispatch(setLastVisible(hasMore ? last : null));
      dispatch(setHasMore(hasMore));
      dispatch(setOrder(normalizeOrders));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOrdersByPhoneNumber = createAsyncThunk(
  "orders/fetchOrdersByPhoneNumber",
  async (phoneNumber: string, { rejectWithValue, dispatch }) => {
    try {
      const byPhoneNumberQuery = query(
        collection(firestore, "payments"),
        where("userPhone", "==", phoneNumber)
      );

      const byPhoneNumberSnapshot = await getDocs(byPhoneNumberQuery);

      const ordersData: IOrder[] = byPhoneNumberSnapshot.docs.map((doc) => {
        return { ...(doc.data() as IOrder), paymentId: doc.id };
      });

      const paymentIds = ordersData.map((order) => order.paymentId);

      const ticketsQuery = query(
        collection(firestore, "tickets"),
        where("paymentId", "in", paymentIds)
      );

      const ticketsSnapshot = await getDocs(ticketsQuery);

      const ticketsData: ITticket[] = ticketsSnapshot.docs.map(
        (doc) => doc.data() as ITticket
      );

      const normalizeOrders = ordersData.reduce((acc, order) => {
        const ticketsForOrder = ticketsData.filter(
          (ticket) => ticket.paymentId === order.paymentId
        );
        return {
          ...acc,
          [order.paymentId]: { ...order, tickets: ticketsForOrder },
        };
      }, {});

      dispatch(setLastVisible(null));
      dispatch(setHasMore(null));
      return normalizeOrders;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOrdersByPaymentId = createAsyncThunk(
  "orders/fetchOrdersByPaymentId",
  async (paymentId: string, { rejectWithValue, dispatch }) => {
    try {
      const paymentDocRef = doc(firestore, "payments", paymentId);
      onSnapshot(paymentDocRef, (doc) => {
        const data = doc.data() as IOrder;
        dispatch(setOrderByid(data));
      });

      return null;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteOrderById = createAsyncThunk(
  "orders/deleteOrderById",
  async (paymentId: string, { rejectWithValue, dispatch }) => {
    try {
      try {
        const ticketsQuery = query(
          collection(firestore, "tickets"),
          where("paymentId", "==", paymentId)
        );
        const ticketsSnapshot = await getDocs(ticketsQuery);

        const deleteTicketPromises = ticketsSnapshot.docs.map((doc) =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deleteTicketPromises);
        await declinePayment(paymentId);
      } catch (error) {}
      await deleteDoc(doc(firestore, "payments", paymentId));
      dispatch(deleteOrder(paymentId));

      return null;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
