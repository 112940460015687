import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

interface ISeoProps {
  title: string;
  description: string;
  canonicalLink?: string;
}

export default function Seo({ title, description, canonicalLink }: ISeoProps) {
  const { t, i18n } = useTranslation();

  return (
    <Helmet>
      <title>{t(`${title}`)}</title>
      <link
        rel="canonical"
        href={`https://east-express.net.ua/${i18n.language}/${canonicalLink}`}
      />
      <meta key="description" content={t(`${description}`)} />
      <meta property="og:title" content={t(`${title}`)} />
      <meta name="twitter:title" content={t(`${title}`)} />
      <meta property="og:description" content={t(`${description}`)} />
      <meta name="twitter:description" content={t(`${description}`)} />
    </Helmet>
  );
}
