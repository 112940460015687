import { Menu, Button, useMediaQuery, Theme } from "@mui/material";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useAuth } from "features/auth/useAuth";

import SinginItems from "./SinginItems";
import NotSinginItems from "./NotSinginItems";
import { FlexGrowWrapper } from "../style";
import { useTranslation } from "react-i18next";

export default function SinginMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isSignedIn } = useAuth();
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (isSmallScreen) {
      setAnchorEl(null);
    }
  }, [isSmallScreen]);

  const handleOpenAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  return (
    <FlexGrowWrapper>
      <Button
        disableRipple
        size="medium"
        variant="text"
        color="inherit"
        disableFocusRipple
        disableTouchRipple
        disableElevation
        sx={{ textTransform: "none", fontSize: 16, fontWeight: 400 }}
        onClick={handleOpenAnchor}
        startIcon={<PersonIcon />}
      >
        {t("personalCabinet")}
      </Button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseAnchor}
      >
        {isSignedIn ? (
          <SinginItems handleClose={handleCloseAnchor} />
        ) : (
          <NotSinginItems handleClose={handleCloseAnchor} />
        )}
      </Menu>
    </FlexGrowWrapper>
  );
}
