import { createAsyncThunk } from "@reduxjs/toolkit";

import { doc, Timestamp, setDoc, getDoc } from "firebase/firestore";

import { USERS } from "services";
import { firestore } from "services/firebase";
import { IUser } from "./types";

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, data }: { userId: string; data: IUser }, thunkAPI) => {
    try {
      const userRef = doc(firestore, USERS, userId);
      const userSnap = await getDoc(userRef);
      const user = userSnap.data();

      const normalizedData = {
        ...user,
        ...data,
        updated: Timestamp.now(),
        version: (user?.version || 0) + 1,
        role: user?.role || "user",
      };
      try {
        await setDoc(doc(firestore, USERS, userId), normalizedData, {
          merge: true,
        });
      } catch (error) {}
      return { ...normalizedData, id: userId };
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
