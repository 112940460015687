import { IStore } from "store/store";

export const selectIsLoading = (state: IStore) => state.buses.isLoading;
export const selectError = (state: IStore) => state.buses.busesError;
export const selectBusesData = (state: IStore) => state.buses.buses;

export const selectClosestBus = (state: IStore) => state.buses.closestBus;
export const selectClosestBusError = (state: IStore) =>
  state.buses.closestError;
export const selectLastError = (state: IStore) => state.buses.lastError;
  

