import { useSelector } from "react-redux";
import { IUseNews } from "./types";
import {
  selectCurrentNews,
  selectError,
  selectHasMore,
  selectLastVisible,
  selectLoadingCurrent,
  selectLoadingNews,
  selectLoadingNextPage,
  selectNews,
} from "./selector";

import {
  deleteNewsById,
  fetchNews,
  fetchNextPageOfNews,
  fetchCurrentNews,
  submitNews,
  updateNews,
} from "./operation";
import { useAppDispatch } from "store/hooks";
import { revoke, revokeCurrent } from "./slice";

export const useNews = (): IUseNews => {
  const newsData = useSelector(selectNews);
  const error = useSelector(selectError);
  const loadingNews = useSelector(selectLoadingNews);
  const loadingNextPage = useSelector(selectLoadingNextPage);
  const lastVisible = useSelector(selectLastVisible);
  const hasMore = useSelector(selectHasMore);
  const сurrentNews = useSelector(selectCurrentNews);
  const loadingCurrent = useSelector(selectLoadingCurrent);

  const dispatch = useAppDispatch();

  return {
    сurrentNews,
    newsData,
    loadingNews,
    error,
    lastVisible,
    hasMore,
    loadingNextPage,
    loadingCurrent,

    fetchNews: () => {
      dispatch(fetchNews());
    },

    submitNews: (data) => {
      dispatch(submitNews(data));
    },

    deleteNewsById: (newsId) => {
      dispatch(deleteNewsById(newsId));
    },

    fetchNextPageOfNews: () => {
      dispatch(fetchNextPageOfNews());
    },

    revoke: () => {
      dispatch(revoke());
    },

    fetchCurrentNews: (newsId) => {
      dispatch(fetchCurrentNews(newsId));
    },

    updateNews: (newsId, news) => {
      dispatch(updateNews({ id: newsId, newNews: news }));
    },

    revokeCurrent: () => {
      dispatch(revokeCurrent());
    },
  };
};
