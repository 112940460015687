import { createSlice } from "@reduxjs/toolkit";
import { IModalState } from "./types";

const initialState: IModalState = {
  isOpen: false,
  isLoginForm: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    handleOpen: (state) => {
      state.isOpen = true;
      state.isLoginForm = true;
    },
    handleClose: (state) => {
      state.isOpen = false;
    },
    toggleForm: (state) => {
      state.isLoginForm = !state.isLoginForm;
    },
  },
});
export const { handleOpen, handleClose, toggleForm } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
