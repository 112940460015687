import { useSelector } from "react-redux";
import { IUsePoints } from "./types";
import { fetchPoints } from "./operation";
import { getPointsData, getPointsError, getPointsLoading } from "./selector";
import { useAppDispatch } from "store/hooks";

export const usePoints = (): IUsePoints => {
  const error = useSelector(getPointsError);
  const poitns = useSelector(getPointsData);
  const isLoading = useSelector(getPointsLoading);

  const dispatch = useAppDispatch();

  return {
    error,
    poitns,
    isLoading,
    getPoints: () => {
      dispatch(fetchPoints());
    },
  };
};
