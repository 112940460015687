import { IconButton, CircularProgress } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNews } from "features/news/useNews";

interface ILoadEditBtnProps {
  docId: string;
}

export default function LoadEditBtn({ docId }: ILoadEditBtnProps) {
  const [loading, setLoading] = useState(false);
  const { fetchCurrentNews, сurrentNews } = useNews();

  return (
    <>
      <IconButton
        onClick={() => {
          fetchCurrentNews(docId);
          setLoading(true);
        }}
        disabled={сurrentNews?.docId === docId}
        color="info"
        size="medium"
      >
        {!loading ? (
          <EditIcon fontSize="medium" />
        ) : (
          <CircularProgress color="secondary" size={24} />
        )}
      </IconButton>
    </>
  );
}
