import { INews } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  collection,
  doc,
  setDoc,
  Timestamp,
  getDocs,
  query,
  deleteDoc,
  limit,
  startAfter,
  getCountFromServer,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import {
  setLastVisible,
  setHasMore,
  setNews,
  deleteNews,
  setCurrent,
} from "./slice";
import { IStore } from "store/store";
import {
  parseAndUploadImages,
  deleteNewsImages,
  deleteUnusedImages,
} from "./helpers";

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const queryNews = query(collection(firestore, "news"), limit(6));
      const snapshotCountNews = await getCountFromServer(
        query(collection(firestore, "news"))
      );
      const newsCount = snapshotCountNews.data().count;
      const querySnapshot = await getDocs(queryNews);

      if (querySnapshot.empty) {
        dispatch(setHasMore(false));
        return rejectWithValue("empty");
      }

      const reviewsData = querySnapshot.docs.map((doc) => doc.data() as INews);

      const normalizeNews = reviewsData.reduce((acc, e) => {
        return {
          ...acc,
          [e.docId]: e,
        };
      }, {});

      dispatch(setNews(normalizeNews));

      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const hasMore = newsCount > querySnapshot.size;

      dispatch(setLastVisible(hasMore ? last : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchNextPageOfNews = createAsyncThunk(
  "news/fetchNextPageOfNews",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { news } = getState() as IStore;

    try {
      const queryNews = query(
        collection(firestore, "news"),
        startAfter(news.lastVisible),
        limit(6)
      );

      const snapshotCountNews = await getCountFromServer(
        query(collection(firestore, "news"))
      );
      const newsCount = snapshotCountNews.data().count;

      const querySnapshot = await getDocs(queryNews);

      const reviewsData = querySnapshot.docs.map((doc) => doc.data() as INews);
      const normalizeNews = reviewsData.reduce((acc, e) => {
        return {
          ...acc,
          [e.docId]: e,
        };
      }, {});

      dispatch(setNews(normalizeNews));

      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const hasMore =
        newsCount >
        querySnapshot.size + Object.keys(news.newsData || {}).length;

      dispatch(setLastVisible(hasMore ? last : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const submitNews = createAsyncThunk(
  "news/submitNews",
  async (news: string, { rejectWithValue, dispatch }) => {
    try {
      const updatedNews = await parseAndUploadImages(news);
      const newsRef = collection(firestore, "news");
      const newReviewRef = doc(newsRef);

      await setDoc(newReviewRef, {
        text: updatedNews,
        date: Timestamp.now(),
        docId: newReviewRef.id,
      });

      dispatch(
        setNews({
          [newReviewRef.id]: {
            text: updatedNews,
            date: Timestamp.now(),
            docId: newReviewRef.id,
          },
        })
      );
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteNewsById = createAsyncThunk(
  "news/deleteNewsById",
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const { news } = getState() as IStore;

      const newsToDelete = news.newsData && news.newsData[id];

      const newsText = newsToDelete?.text || "";

      await deleteDoc(doc(firestore, "news", id));
      await deleteNewsImages(newsText);
      dispatch(deleteNews(id));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCurrentNews = createAsyncThunk(
  "news/fetchCurrentNews",
  async (newsId: string, { rejectWithValue, dispatch }) => {
    try {
      const queryNews = doc(firestore, "news", newsId);
      const newsSnapshot = await getDoc(queryNews);

      const articleData = newsSnapshot.data() as INews;

      dispatch(setCurrent(articleData));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async (
    { id, newNews }: { id: string; newNews: string },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { news } = getState() as IStore;
      const newsRef = doc(firestore, "news", id);

      const existingNews = news?.сurrentNews?.text || "";
      const newNewsRes = await parseAndUploadImages(newNews);

      try {
        await deleteUnusedImages(existingNews, newNews);
      } catch (error) {}

      await updateDoc(newsRef, {
        text: newNewsRes,
        date: Timestamp.now(),
      });

      dispatch(
        setNews({
          [id]: {
            text: newNewsRes,
            date: Timestamp.now(),
            docId: id,
          },
        })
      );
    } catch (error: any) {
      return rejectWithValue(
        "Ошибка при обновлении новостей: " + error.message
      );
    }
  }
);
