import { createAsyncThunk } from "@reduxjs/toolkit";
import { stops } from "services/functions";
import { IPoints } from "./types";

export const fetchPoints = createAsyncThunk(
  "points/fetchPoints",
  async (_, { rejectWithValue }) => {
    try {
      const response = await stops();

      if (!response.data) {
        return rejectWithValue("null");
      }
      return response.data as IPoints[];
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
