import { useSearchForm } from "features/searchform/useShopForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { busRoutesCollection } from "./constans";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { nanoid } from "nanoid";
import {
  SectionTitle,
  ListWrapper,
  GreyKeyboardArrowRightIcon,
  ListItemText,
} from "./style";

export default function BusRoutes() {
  const [shuffledBusRoutes, setShuffledBusRoutes] = useState([
    ...busRoutesCollection,
  ]);

  const { setFormData } = useSearchForm();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShuffledBusRoutes((prevRoutes: any) => {
        return [...prevRoutes].sort(() => Math.random() - 1);
      });
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (from: any, to: any) => {
    setFormData({
      direction: "oneway",
      city_from: t(`cityKey-${from}`),
      city_to: t(`cityKey-${to}`),
      date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
    });
    navigate({
      pathname: `/${i18n.language}/buses`,
      search: createSearchParams({
        direction: "oneway",
        city_from: t(`cityKey-${from}`),
        city_to: t(`cityKey-${to}`),
        date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
      }).toString(),
    });
  };

  const ensureMultipleOfSixItems = (items: any[]) => {
    const itemsToAdd = 6 - (items.length % 6);
    if (itemsToAdd === 6) return items; // already a multiple of 6
    const additionalItems = items.slice(0, itemsToAdd);
    return [...items, ...additionalItems];
  };

  const evenRoutes = ensureMultipleOfSixItems(shuffledBusRoutes);
  const halfRoutes = evenRoutes.slice(0, Math.ceil(evenRoutes.length / 2));
  return (
    <>
      <SectionTitle>{t("popularBusRoutes")}</SectionTitle>
      <Grid container component={"ul"} spacing={2}>
        {halfRoutes.map(({ from, to, price }) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            onClick={() => handleClick(from, to)}
            key={nanoid()}
            component={"li"}
          >
            <ListWrapper>
              <ListItemText>
                {`${t(`cityKey-${from}`)}`} <GreyKeyboardArrowRightIcon />{" "}
                <span>{`${t(`cityKey-${to}`)}`}</span>
              </ListItemText>
              {/* <ListItemText>
                {price} <span> UAH</span>
              </ListItemText> */}
            </ListWrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
