interface IBusRoutesCollection {
  from: string;
  fromName: string;
  to: string;
  price: number;
  toName: string;
}

export const busRoutesCollection: IBusRoutesCollection[] = [
  { fromName: "Київ", from: "50", toName: "Прага", to: "134", price: 2800 },
  { fromName: "Київ", from: "50", toName: "Оломоуц", to: "178", price: 2700 },
  { fromName: "Київ", from: "50", toName: "Жешув", to: "84", price: 2000 },

  {
    fromName: "Миколаїв",
    from: "55",
    toName: "Оломоуц",
    to: "178",
    price: 3500,
  },
  { fromName: "Одесса", from: "199", toName: "Прага", to: "134", price: 2900 },
  {
    fromName: "Хмельницький",
    from: "39",
    toName: "Брно",
    to: "198",
    price: 2100,
  },
  {
    fromName: "Хмельницький",
    from: "39",
    toName: "Прага",
    to: "134",
    price: 2100,
  },
  {
    fromName: "Запоріжжя",
    from: "114",
    toName: "Прага",
    to: "134",
    price: 3700,
  },
  {
    fromName: "Запоріжжя",
    from: "114",
    toName: "Прага",
    to: "134",
    price: 3700,
  },
  {
    fromName: "Вінниця",
    from: "40",
    toName: "Прага",
    to: "134",
    price: 2200,
  },
  {
    fromName: "Вінниця",
    from: "40",
    toName: "Жешув",
    to: "84",
    price: 1800,
  },
  {
    fromName: "Дніпро",
    from: "115",
    toName: "Прага",
    to: "134",
    price: 3600,
  },
  {
    fromName: "Дніпро",
    from: "115",
    toName: "Брно",
    to: "198",
    price: 3600,
  },
  {
    fromName: "Дніпро",
    from: "115",
    toName: "Оломоуц",
    to: "178",
    price: 3600,
  },
  {
    fromName: "Одесса",
    from: "199",
    toName: "Оломоуц",
    to: "178",
    price: 2900,
  },
  {
    fromName: "Одесса",
    from: "199",
    toName: "Прага",
    to: "134",
    price: 2900,
  },
  {
    fromName: "Одесса",
    from: "199",
    toName: "Брно",
    to: "198",
    price: 2900,
  },
  {
    fromName: "Одесса",
    from: "199",
    toName: "Катовіце",
    to: "100",
    price: 2500,
  },
  {
    fromName: "Одесса",
    from: "199",
    toName: "Острава",
    to: "82",
    price: 2900,
  },
  {
    fromName: "Херсон",
    from: "60",
    toName: "Прага",
    to: "134",
    price: 3800,
  },
  {
    fromName: "Херсон",
    from: "60",
    toName: "Брно",
    to: "198",
    price: 3800,
  },
  {
    fromName: "Херсон",
    from: "60",
    toName: "Оломоуц",
    to: "178",
    price: 3800,
  },
];
