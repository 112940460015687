import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function LanguagePath() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const curPath = location.pathname;

  useEffect(() => {
    if (lang && i18n.resolvedLanguage !== lang) {
      //@ts-ignore
      if (i18n.options.fallbackLng?.includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        navigate("/" + i18n.resolvedLanguage + curPath, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return <Outlet />;
}
