import { CircularProgress } from "@mui/material";
import { CircularProgressWrapp } from "./style";

interface ICircularLoadingProps {
  size: number;
}

export default function CircularLoading({ size }: ICircularLoadingProps) {
  return (
    <CircularProgressWrapp>
      <CircularProgress color="info" size={size} />
    </CircularProgressWrapp>
  );
}
