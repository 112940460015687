import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
 
export default function BackDiscount() {
  const {t}=useTranslation()
   return (
    <Typography
      sx={{
        fontWeight: 500,
        fontFamily: "Raleway",
        fontSize: 16,
        color: `red`,
       
     
        
        span: {
          fontSize: 12,
          color: `#005094`,

          "@media (min-width:600px)": {
            fontSize: 14,
          },
        },
      }}
    >
      -20% <span>{t("for_the_return_ticket")}</span>
      
    </Typography>
  );
}
