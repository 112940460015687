import { createSlice } from "@reduxjs/toolkit";
import { IReviewsState } from "./types";
import {
  deleteReviewById,
  fetchReviews,
  submitReview,
  fetchApprovedReviews,
  fetchNextPageOfReviews,
  approveReviewById,
} from "./operation";

const initialState: IReviewsState = {
  loadingReviews: false,
  loadingNextPage: false,
  error: null,
  reviews: null,
  lastVisible: null,
  hasMore: true,
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviews: (state: IReviewsState, { payload }) => {
      return { ...state, reviews: { ...state.reviews, ...payload } };
    },

    deleteReviews: (state: IReviewsState, { payload }) => {
      return {
        ...state,
        reviews: { ...state.reviews, [payload]: null },
      };
    },

    approveReview: (state: IReviewsState, { payload }) => {
      return {
        ...state,
        reviews: state.reviews
          ? {
              ...state.reviews,
              [payload]: { ...state.reviews[payload], approved: true },
            }
          : null,
      };
    },

    setLastVisible: (state, { payload }) => {
      return { ...state, lastVisible: payload };
    },

    setHasMore: (state, { payload }) => {
      return { ...state, hasMore: payload };
    },

    revoke: (state) => {
      return {
        loadingReviews: false,
        loadingNextPage: false,
        error: null,
        reviews: null,
        lastVisible: null,
        hasMore: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviews.pending, (state: IReviewsState, { payload }) => {
        return { ...state, loadingReviews: true };
      })
      .addCase(fetchReviews.fulfilled, (state: IReviewsState, { payload }) => {
        return { ...state, loadingReviews: false };
      })
      .addCase(fetchReviews.rejected, (state: IReviewsState, { payload }) => {
        return { ...state, loadingReviews: false, error: payload as string };
      })
      .addCase(submitReview.pending, (state: IReviewsState, { payload }) => {
        return { ...state, loadingReviews: true };
      })
      .addCase(submitReview.fulfilled, (state: IReviewsState, { payload }) => {
        return {
          ...state,
          loadingReviews: false,
        };
      })
      .addCase(submitReview.rejected, (state: IReviewsState, { payload }) => {
        return { ...state, loadingReviews: false, error: payload as string };
      })
      .addCase(
        deleteReviewById.pending,
        (state: IReviewsState, { payload }) => {
          return { ...state };
        }
      )
      .addCase(
        deleteReviewById.fulfilled,
        (state: IReviewsState, { payload }) => {
          return { ...state };
        }
      )
      .addCase(
        deleteReviewById.rejected,
        (state: IReviewsState, { payload }) => {
          return { ...state, error: payload as string };
        }
      )
      .addCase(
        approveReviewById.rejected,
        (state: IReviewsState, { payload }) => {
          return { ...state, error: payload as string };
        }
      )
      .addCase(
        fetchApprovedReviews.pending,
        (state: IReviewsState, { payload }) => {
          return { ...state, loadingReviews: true };
        }
      )
      .addCase(
        fetchApprovedReviews.fulfilled,
        (state: IReviewsState, { payload }) => {
          return { ...state, loadingReviews: false };
        }
      )
      .addCase(
        fetchApprovedReviews.rejected,
        (state: IReviewsState, { payload }) => {
          return { ...state, loadingReviews: false, error: payload as string };
        }
      )
      .addCase(
        fetchNextPageOfReviews.pending,
        (state: IReviewsState, { payload }) => {
          return { ...state, loadingNextPage: true };
        }
      )
      .addCase(
        fetchNextPageOfReviews.fulfilled,
        (state: IReviewsState, { payload }) => {
          return {
            ...state,
            loadingNextPage: false,
          };
        }
      )
      .addCase(
        fetchNextPageOfReviews.rejected,
        (state: IReviewsState, { payload }) => {
          return { ...state, loadingNextPage: false, error: payload as string };
        }
      );
  },
});
export const {
  setReviews,
  setLastVisible,
  setHasMore,
  deleteReviews,
  approveReview,
  revoke,
} = reviewsSlice.actions;

export const reviewsReducer = reviewsSlice.reducer;
