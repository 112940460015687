import { IReviews } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  getDocs,
  query,
  deleteDoc,
  where,
  limit,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import {
  setLastVisible,
  setHasMore,
  setReviews,
  deleteReviews,
  approveReview,
} from "./slice";
import { IStore } from "store/store";

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async (_, { dispatch, rejectWithValue, getState }) => {
    const { user } = getState() as IStore;

    try {
      const roleReviewsQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "reviews"), limit(6))
          : query(
              collection(firestore, "reviews"),
              where("userId", "==", user.data?.id),
              limit(6)
            );

      const countReviewsQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "reviews"))
          : query(
              collection(firestore, "reviews"),
              where("userId", "==", user.data?.id)
            );

      const querySnapshot = await getDocs(roleReviewsQuery);

      if (querySnapshot.empty) {
        return rejectWithValue("Empty");
      }
      const snapshotCountReviews = await getCountFromServer(countReviewsQuery);
      const reviewsCount = snapshotCountReviews.data().count;

      const reviewsData = querySnapshot.docs.map(
        (doc) => ({ ...doc.data(), docId: doc.id } as IReviews)
      );

      const normalizeReviews = reviewsData.reduce((acc, review) => {
        acc[review.docId] = review;
        return acc;
      }, {} as Record<string, IReviews>);

      dispatch(setReviews(normalizeReviews));

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      const hasMore = reviewsCount > querySnapshot.size;

      dispatch(setLastVisible(hasMore ? lastVisible : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchNextPageOfReviews = createAsyncThunk(
  "reviews/fetchNextPageOfReviews",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { user, reviews } = getState() as IStore;

    try {
      // Проверка на наличие последнего видимого документа
      if (!reviews.lastVisible) {
        throw new Error("No last visible document to start after");
      }

      const roleReviewsQuery =
        user.data?.role === "admin"
          ? query(
              collection(firestore, "reviews"),
              startAfter(reviews.lastVisible),
              limit(6)
            )
          : query(
              collection(firestore, "reviews"),
              where("userId", "==", user.data?.id),
              startAfter(reviews.lastVisible),
              limit(6)
            );

      const countReviewsQuery =
        user.data?.role === "admin"
          ? query(collection(firestore, "reviews"))
          : query(
              collection(firestore, "reviews"),
              where("userId", "==", user.data?.id)
            );

      const snapshotCountReviews = await getCountFromServer(countReviewsQuery);
      const reviewsCount = snapshotCountReviews.data().count;

      const querySnapshot = await getDocs(roleReviewsQuery);

      const reviewsData = querySnapshot.docs.map(
        (doc) => doc.data() as IReviews
      );

      const normalizeReviews = reviewsData.reduce((acc, e) => {
        return {
          ...acc,
          [e.docId]: e,
        };
      }, {} as Record<string, IReviews>);

      dispatch(setReviews(normalizeReviews));

      const last = querySnapshot.docs[querySnapshot.docs.length - 1];

      const hasMore =
        reviewsCount >
        querySnapshot.size + Object.keys(reviews.reviews || {}).length;

      dispatch(setLastVisible(hasMore ? last : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchApprovedReviews = createAsyncThunk(
  "reviews/fetchApprovedReviews",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const approvedReviewsQuery = query(
        collection(firestore, "reviews"),
        where("approved", "==", true),
        limit(6)
      );

      const approvedReviewsCountQuery = query(
        collection(firestore, "reviews"),
        where("approved", "==", true)
      );

      const approvedReviewsSnapshot = await getDocs(approvedReviewsQuery);

      if (approvedReviewsSnapshot.empty) {
        return rejectWithValue("Empty");
      }

      const snapshotApprovedReviewsCount = await getCountFromServer(
        approvedReviewsCountQuery
      );

      const reviewsCount = snapshotApprovedReviewsCount.data().count;

      const approvedReviewsData = approvedReviewsSnapshot.docs.map(
        (doc) => doc.data() as IReviews
      );

      const normalizeReviews = approvedReviewsData.reduce((acc, review) => {
        acc[review.docId] = review;
        return acc;
      }, {} as Record<string, IReviews>);

      dispatch(setReviews(normalizeReviews));

      const lastVisible =
        approvedReviewsSnapshot.docs[approvedReviewsSnapshot.docs.length - 1];

      const hasMore = reviewsCount > approvedReviewsSnapshot.size;

      dispatch(setLastVisible(hasMore ? lastVisible : null));
      dispatch(setHasMore(hasMore));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const submitReview = createAsyncThunk(
  "reviews/submitReview",
  async (reviews: IReviews, { rejectWithValue, dispatch, getState }) => {
    try {
      const reviewsRef = collection(firestore, "reviews");
      const { user } = getState() as IStore;
      const newReviewRef = doc(reviewsRef);

      await setDoc(newReviewRef, {
        ...reviews,
        date: Timestamp.now(),
        docId: newReviewRef.id,
        userName: user?.data?.name || "",
        userSurname: user.data?.surname,
      });
      dispatch(
        setReviews({
          [newReviewRef.id]: {
            ...reviews,
            date: Timestamp.now(),
            docId: newReviewRef.id,
            userName: user.data?.name,
            userSurname: user.data?.surname,
          },
        })
      );
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteReviewById = createAsyncThunk(
  "reviews/deleteReviewById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await deleteDoc(doc(firestore, "reviews", id));
      dispatch(deleteReviews(id));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const approveReviewById = createAsyncThunk(
  "reviews/approveReviewById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await setDoc(
        doc(firestore, "reviews", id),
        {
          approved: true,
        },
        { merge: true }
      );
      dispatch(approveReview(id));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
