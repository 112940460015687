import { useTranslation } from "react-i18next";
import { BuyTicketsContainer, BuyTicketsTitle } from "../style";
import MainForm from "./MainForm";

export default function BuyTickets() {
  const { t } = useTranslation();
  return (
    <BuyTicketsContainer>
      <BuyTicketsTitle as={"h1"}>{t("herowTitle")}</BuyTicketsTitle>
      <MainForm />
    </BuyTicketsContainer>
  );
}
