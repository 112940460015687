import dayjs from "dayjs";
import { IPrice } from "./types";
import { IBusesData } from "features/buses/types";

export function getDateRange(date: string) {
  const today = dayjs(date);
  const yesterday = today.subtract(1, "day");
  const tomorrow = today.add(1, "day");
  const array = [yesterday, today, tomorrow].map((date) =>
    date.format("YYYY-MM-DD")
  );
  return array;
}

export const findePrice = (prices: IPrice[] = []) =>
  Math.floor(Number(prices.find((price) => price.Currency === "UAH")?.Amount));

export function busesResultCount(busesData: IBusesData[] | null = []) {
  return busesData ? busesData?.filter((e) => e.freeSeats).length : 0;
}

export const blockedIds = [
  "129", "156", "85", "197", "90", "144", "162", "158", "76", "62",
  "66", "49", "111", "150", "157", "98", "64", "73", "146", "153",
  "65", "161", "127", "110", "118", "120", "94", "126", "123", "121",
  "149", "128", "163", "177", "130", "95", "75", "174", "74", "196",
  "63", "137", "91", "215", "131", "155", "151", "165", "176", "152",
  "116", "171", "172", "135", "61", "136", "93", "119", "79", "167",
  "112", "67", "219", "138", "183", "216", "159", "96", "78", "195",
  "77", "148", "214", "154", "173", "122", "124", "164", "92", "213",
  "113", "160", "166", "170"
];