import { FastField, useField } from "formik";
import { memo } from "react";
import { TextField } from "@mui/material";

interface IFastTextFieldProps {
  name: string;
  multiline?: boolean;
  rows?: number;
  type: string;
  placeholder?: string;
  readonly?: boolean;
}

const FastTextField = ({
  type,
  name,
  multiline,
  readonly,
  rows,
  placeholder,
}: IFastTextFieldProps) => {
  const [field, meta] = useField(name);

  return (
    <FastField
      {...field}
      as={TextField}
      id={name}
      type={type}
      fullWidth
      size="small"
      placeholder={placeholder}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      multiline={multiline}
      rows={multiline ? rows : 0}
      disabled={readonly}
    />
  );
};

export default memo(FastTextField);
