import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSearchForm } from "features/searchform/useShopForm";
import { useField } from "formik";
import { memo } from "react";

interface ICustomDatePickerProps {
  name: string;
  label: string;
  backPicker?: boolean;
  mindate?: boolean;
}

const DatePicker = ({
  name,
  label,
  backPicker,
  mindate,
}: ICustomDatePickerProps) => {
  const [field, meta] = useField(name);

  const { formData, setFormData } = useSearchForm();
  return (
    <MuiDatePicker
      {...field}
      format="DD MMM"
      label={label}
      value={dayjs(field.value)}
      onChange={(newValue) => {
        backPicker
          ? setFormData({
              [name]: dayjs(String(newValue)).format("YYYY-MM-DD"),
              date_return: dayjs(String(newValue))
                .add(1, "day")
                .format("YYYY-MM-DD"),
            })
          : setFormData({
              [name]: dayjs(String(newValue)).format("YYYY-MM-DD"),
              date_return: dayjs(String(newValue))
                .add(1, "day")
                .format("YYYY-MM-DD"),
            });
      }}
      minDate={
        mindate ? dayjs(formData?.date_sent).add(1, "day") : dayjs(new Date())
      }
      slotProps={{
        textField: () => ({
          readOnly: true,
          fullWidth: true,
          name: field.name,
          size: "small",
          error: meta.touched && Boolean(meta.error),
        }),
      }}
    />
  );
};

export default memo(DatePicker);
