import { IStore } from "../../store/store";
import { createSelector } from "reselect";
import { IUserState, TRole } from "./types";

const getUser = (state: IStore): IUserState => state.user;

export const getRole = createSelector(
  getUser,
  (user: IUserState): TRole | undefined => user.data?.role
);

export const getUserError = (state: IStore) => state.user.error;
export const getUserData = (state: IStore) => state.user.data;
export const getUserId = (state: IStore) => state.user.data?.id;
export const getIsloading = (state: IStore) => state.user.isLoading;
