import applepay from "./images/applepay.png";
import googlePay from "./images/google-pay.png";
import mastercard from "./images/mastercard.png";
import visa from "./images/visa.png";
import { FacebookGrey, InstagramGrey } from "./style";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

export interface ContactItem {
  icon?: JSX.Element;
  link?: string;
  label: string;
}

interface ContactsObject {
  companyNameUkraine: ContactItem[];
  companyCz: ContactItem[];
  paymentIcons: {
    src: string;
    alt: string;
    width: string;
    height: string;
  }[];
  pagesDocument: {
    title: string;
    link: string;
  }[];
  pagesInfo: {
    title: string;
    link: string;
  }[];
  socialLinks: {
    link: string;
    icon: JSX.Element;
  }[];
}

export const footerContent: ContactsObject = {
  companyNameUkraine: [
    {
      label: "+380996033832",
      link: "tel:+380996033832",
      icon: <CallIcon color="action" fontSize="small" />,
    },
    {
      label: "+380983619098",
      link: "tel:+380983619098",
      icon: <CallIcon color="action" fontSize="small" />,
    },
    {
      label: "+380980823192",
      link: "tel:+380980823192",
      icon: <CallIcon color="action" fontSize="small" />,
    },
    {
      label: "Praha-bus@ukr.net",
      link: "mailto:Praha-bus@ukr.net",
      icon: <AlternateEmailIcon color="action" fontSize="small" />,
    },
  ],
  companyCz: [
    { label: "Křižíkova 344/6, IČ: 44794363" },
    { label: "18600 Praha, Karlín" },
    {
      label: "+420777719524",
      link: "tel:+420777719524",
      icon: <CallIcon color="action" fontSize="small" />,
    },
    {
      label: "+420776109386",
      link: "tel:+420776109386",
      icon: <CallIcon color="action" fontSize="small" />,
    },
    {
      label: "rezervacejizdenek@musiltour.cz",
      link: "mailto:rezervacejizdenek@musiltour.cz",
      icon: <AlternateEmailIcon color="action" fontSize="small" />,
    },
  ],
  paymentIcons: [
    {
      src: applepay,
      alt: "applepay",
      width: "auto",
      height: "25",
    },
    { src: visa, alt: "visa", width: "auto", height: "20" },
    {
      src: mastercard,
      alt: "mastercard",
      width: "auto",
      height: "30",
    },
    {
      src: googlePay,
      alt: "googlePay",
      width: "auto",
      height: "30",
    },
  ],
  pagesDocument: [
    { title: "privacyPolicy", link: "/privacypolicy" },
    { title: "transportRules", link: "/rules" },
    { title: "oferta", link: "/oferta" },
  ],
  pagesInfo: [
    { title: "frequentlyAskedQuestions", link: "/faq" },
    { title: "news", link: "/news" },
    { title: "aboutUs", link: "/aboutus" },
    { title: "contacts", link: "/contacts" },
  ],
  socialLinks: [
    {
      link: "https://www.facebook.com/share/M4xwUaVdvEV87eVr/?mibextid=LQQJ4d",
      icon: <FacebookGrey fontSize="large" />,
    },
    {
      link: "https://www.instagram.com/praha_east_express/",
      icon: <InstagramGrey fontSize="large" />,
    },
  ],
};
