import { FastField, useField } from "formik";
import { memo } from "react";
import { MuiTelInput } from "mui-tel-input";

interface IFastPhoneFieldProps {
  name: string;
}

const FastPhoneField = ({ name }: IFastPhoneFieldProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FastField
      {...field}
      as={MuiTelInput}
      size="small"
      fullWidth
      forceCallingCode
      defaultCountry="UA"
      disableFormatting
      onlyCountries={["UA", "CZ", "PL", "DE", "BE"]}
      id={name}
      onChange={(newValue: string) => {
        helpers.setValue(newValue);
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default memo(FastPhoneField);
