import { IStore } from "../../store/store";

export const selectNews = (state: IStore) => state.news.newsData;
export const selectError = (state: IStore) => state.news.error;
export const selectLoadingNews = (state: IStore) => state.news.loadingNews;
export const selectLoadingNextPage = (state: IStore) =>
  state.news.loadingNextPage;
export const selectLastVisible = (state: IStore) => state.news.lastVisible;
export const selectHasMore = (state: IStore) => state.news.hasMore;
export const selectCurrentNews = (state: IStore) => state.news.сurrentNews;
export const selectLoadingCurrent = (state: IStore) =>
  state.news.loadingCurrent;
