import {
  ContactsText,
  FooterContainer,
  FooterWrapper,
  ColumnListBox,
  RowListBox,
  ListTitle,
  LinkBox,
} from "./style";

import { Box, Container, Divider, Grid, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";
import { footerContent } from "./helpers";
import SocialLinkItem from "./components/SocialLinkItem";
import Copyright from "./components/Copyright";
import Link from "components/Link";
import NavigateLinkItem from "./components/NavigateLinkItem";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper component="footer">
      <Container maxWidth="lg">
        <FooterContainer container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <ListTitle>{t("informationHeading")}</ListTitle>
                <ColumnListBox>
                  {footerContent.pagesInfo.map(({ title, link }) => (
                    <NavigateLinkItem
                      title={title}
                      link={link}
                      key={nanoid()}
                    />
                  ))}
                </ColumnListBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ListTitle>{t("documents")}</ListTitle>
                <ColumnListBox>
                  {footerContent.pagesDocument.map(({ title, link }) => (
                    <NavigateLinkItem
                      title={title}
                      link={link}
                      key={nanoid()}
                    />
                  ))}
                </ColumnListBox>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ListTitle>{t("companyNameUkraine")}</ListTitle>
                <ColumnListBox>
                  {footerContent.companyNameUkraine.map((contact) => (
                    <ListItem key={nanoid()}>
                      {contact.link ? (
                        <>
                          {contact.icon}

                          <LinkBox>
                            <Link isGlobal to={contact.link}>
                              {t(contact.label)}
                            </Link>
                          </LinkBox>
                        </>
                      ) : (
                        <ContactsText>{contact.label}</ContactsText>
                      )}
                    </ListItem>
                  ))}
                </ColumnListBox>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ListTitle>{t("companyName")}</ListTitle>
                <ColumnListBox>
                  {footerContent.companyCz.map((contact) => (
                    <ListItem key={nanoid()}>
                      {contact.link ? (
                        <>
                          {contact.icon}
                          <LinkBox>
                            <Link isGlobal to={contact.link}>
                              {t(contact.label)}
                            </Link>
                          </LinkBox>
                        </>
                      ) : (
                        <ContactsText>{contact.label}</ContactsText>
                      )}
                    </ListItem>
                  ))}
                </ColumnListBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12} sm={8} md={8}>
                <RowListBox container>
                  {footerContent.paymentIcons.map(
                    ({ src, alt, width, height }) => (
                      <Grid item xs={3} key={nanoid()}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={src}
                            alt={alt}
                            width={width}
                            height={height}
                          />
                        </Box>
                      </Grid>
                    )
                  )}
                </RowListBox>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <ListTitle>{t("socialMediaHeading")}</ListTitle>
                <RowListBox>
                  {footerContent.socialLinks.map((e) => (
                    <SocialLinkItem
                      href={e.link}
                      icon={e.icon}
                      key={nanoid()}
                    />
                  ))}
                </RowListBox>
              </Grid>
            </Grid>
          </Grid>
        </FooterContainer>
        <Divider />
        <Copyright />
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
