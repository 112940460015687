import { Box, Paper, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const PrimeTextColor = `rgb(66, 69, 67)`;

export const PreviewNewsCardImage = styled(Box)(({ theme: { spacing } }) => ({
  img: {
    width: "100%",
    maxHeight: 250,
    minHeight: "100%",
    objectFit: "cover",
    display: "block",
  },
}));

export const PreviewNewsCardTitle = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTextColor,
    fontWeight: 500,
    fontSize: 14,
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
    maxHeight: 50,
    minHeight: 50,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    overflow: "hidden",
    "@media (min-width:600px)": {
      fontSize: 14,
    },
  })
);

export const PreviewNewsCardDescription = styled(Typography)(
  ({ theme: { spacing } }) => ({
    color: PrimeTextColor,
    fontWeight: 300,
    fontSize: 12,
    marginTop: spacing(0.5),
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  })
);

export const CartMetaContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: spacing(0.2),
  alignSelf: "flex-end",
}));

export const CartMetaIcon = styled(AccessTimeIcon)(
  ({ theme: { spacing } }) => ({
    color: grey[400],
    fontSize: 14,
  })
);

export const CartMetaText = styled(Typography)(({ theme: { spacing } }) => ({
  color: grey[400],
  fontWeight: 400,
  fontSize: 12,
}));

export const NewsCardContainer = styled(Paper)(({ theme: { spacing } }) => ({
  overflow: "hidden",
  borderRadius: spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  border: `1px solid ${grey[400]}`,

  transition: "transform 0.3s ease",

  ":hover": {
    transform: "scale(1.01)",
  },
}));

export const NewsCardContent = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(0.5),
  padding: spacing(2),
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTextColor,
  fontWeight: 500,
  fontSize: 18,
  maxHeight: 50,
  overflow: "hidden",
  marginTop: spacing(4),
  marginBottom: spacing(1),

  "@media (min-width:600px)": {
    fontSize: 20,
    marginTop: spacing(6),
    marginBottom: spacing(2),
  },
}));
