import { IconButton, Tooltip, Theme, useMediaQuery } from "@mui/material";
import { nanoid } from "nanoid";
import ContactsItem from "./ContactsItem";
import { MouseEvent, useEffect, useState } from "react";
import { contactData } from "../constans";
import {
  ContactsMenuContainer,
  FiPhoneCallWhite,
  FlexGrowWrapper,
} from "../style";

export default function ContactsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (isSmallScreen) {
      setAnchorEl(null);
    }
  }, [isSmallScreen]);

  const handleOpenAnchor = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  return (
    <FlexGrowWrapper>
      <Tooltip title="Відкрити меню">
        <IconButton
          color="inherit"
          disableRipple
          size="medium"
          onClick={handleOpenAnchor}
        >
          <FiPhoneCallWhite size={25} />
        </IconButton>
      </Tooltip>
      <ContactsMenuContainer
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseAnchor}
      >
        {contactData.map((el) => (
          <ContactsItem
            contact={el}
            handleCloseUserMenu={handleCloseAnchor}
            key={nanoid()}
          />
        ))}
      </ContactsMenuContainer>
    </FlexGrowWrapper>
  );
}
