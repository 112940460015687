import {
   FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
 import dayjs from "dayjs";
import { useSearchForm } from "features/searchform/useShopForm";
import { useTickets } from "features/tickets/useTickets";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

interface IDirectionRadioBtn {
  name: string;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    color: grey[700],
    fontSize: 14,
    "@media (min-width:600px)": {
      fontSize: 16,
    },
  },
}));

 
const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "flex-start",
}));

export default function DirectionRadioBtn({ name }: IDirectionRadioBtn) {
  const [field, , helpers] = useField(name);
  const { setFormData, formData } = useSearchForm();
  const { clearTickets } = useTickets();
  const { t } = useTranslation();

  return (
    <StyledRadioGroup {...field} aria-labelledby="demo-customized-radios">
      <StyledFormControlLabel
        value="oneway"
        control={<Radio color="info" size="medium" disableRipple />}
        label={t("oneway")}
        onChange={() => {
          setFormData({ [name]: "oneway" });
          clearTickets();
          helpers.setValue("oneway");
        }}
      />
     
        <StyledFormControlLabel
          value="andBack"
          label={t("thoway")}
          control={<Radio color="info" size="medium" disableRipple />}
          onChange={() => {
            setFormData({
              [name]: "andBack",
              date_return: dayjs(formData.date_sent)
                .add(1, "day")
                .format("YYYY-MM-DD"),
            });
            clearTickets();
            helpers.setValue("andBack");
          }}
        />
       
     
    </StyledRadioGroup>
  );
}
