import {
  AccordionSummary,
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
  Menu,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";

export const AppBarContainer = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "#005094",
}));

export const SmallScreenWrapper = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    flexGrow: 0,
    [breakpoints.up("sm")]: {
      display: "none",
    },
    [breakpoints.down("sm")]: {
      display: "flex",
      gap: spacing(1),
      alignItems: "center",
      marginLeft: "auto",
    },
  })
);

export const LargeScreenWrapper = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    flexGrow: 0,
    [breakpoints.down("sm")]: {
      display: "none",
    },
    [breakpoints.up("sm")]: {
      display: "flex",
      gap: spacing(4),
      alignItems: "center",
      marginLeft: "auto",
    },
  })
);

export const LargeDrawer = styled(Drawer)(
  ({ theme: { spacing, breakpoints } }) => ({
    "& .MuiPaper-root": { width: "100%" },
  })
);

export const BurgerHeaderWrapper = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  })
);

export const BurgeListWrapper = styled(List)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
    marginBottom: spacing(1),
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  })
);

export const BurgeLangWrapper = styled(Box)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: spacing(1),
    paddingBottom: spacing(2),
    paddingTop: spacing(2),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  })
);

export const BurgeLangText = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  color: "rgb(66, 69, 67)",
  "&:hover, &:focus, &:active": {
    textDecoration: "underline",
  },
});

export const BurgerAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const AccordionSummaryTitle = styled(Typography)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginLeft: spacing(1),
    fontSize: 16,
    fontWeight: 500,
    color: "rgb(66, 69, 67)",
    "&:hover, &:focus, &:active": {
      textDecoration: "underline",
    },
  })
);

export const AccordionSummaryList = styled(List)(
  ({ theme: { spacing, breakpoints } }) => ({
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
  })
);

export const ContactsListItem = styled(ListItem)(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: "8px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  })
);

export const ContactsItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ContactsListLink = styled(Link)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginLeft: 4,
    fontSize: 14,
    fontWeight: 400,
    color: "rgb(66, 69, 67)",
    textWrap: "nowrap",

    "&:hover, &:focus, &:active": {
      textDecoration: "underline",
    },
  })
);

export const FiPhoneCallWhite = styled(FiPhoneCall)({
  color: "#fffff",
});

export const ContactsMenuContainer = styled(Menu)(
  ({ theme: { spacing, breakpoints } }) => ({
    "& .MuiPaper-root": {
      padding: spacing(1),
    },
    "& .MuiMenu-list": {
      display: "flex",
      flexDirection: "column",
      gap: spacing(1),
    },
  })
);

export const FlexGrowWrapper = styled(Box)({
  flexGrow: 0,
});

export const LinkBox = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  fontSize: 16,
  fontWeight: 400,
  textAlign: "center",
  color: "rgb(66, 69, 67)",
  "&:hover, &:focus, &:active": {
    textDecoration: "underline",
  },
}));

export const AuthLink = styled(Link)(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  "@media (min-width:600px)": {
    padding: "10px 16px",
  },
}));

export const AuthLinkText = styled(Typography)(
  ({ theme: { spacing, breakpoints } }) => ({
    marginLeft: spacing(1),
    fontSize: 16,
    fontWeight: 400,
    "&:hover, &:focus, &:active": {
      textDecoration: "underline",
    },
  })
);

interface IBlackColor {
  props: { black?: boolean };
}

export const StyledSelect = styled(Select)<IBlackColor>(
  ({ theme: { spacing, breakpoints }, props: { black } }) => ({
    color: black ? "#000000" : "#ffff",

    ".MuiSelect-icon": { color: black ? "#000000" : "#ffff" },
  })
);

export const SelectItemBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: spacing(1),
  textTransform: "uppercase",
}));
