import { memo, SyntheticEvent, useEffect, useState } from "react";
import { TabContainer, TabElement, TabWrapper } from "./style";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { useTickets } from "features/tickets/useTickets";
import { useSearchForm } from "features/searchform/useShopForm";
import { getDateRange } from "./helpers";
import { Idata } from "pages/Profile/BusesManagement/types";
import { useTranslation } from "react-i18next";

function DatePagination() {
  const { formData, setFormData } = useSearchForm();
  const [data, setData] = useState<Idata>({
    dataGrid: null,
    tabValue: formData?.date_sent || dayjs(new Date()).format("YYYY-MM-DD"),
  });
  const { i18n } = useTranslation();

  const { ticketsTo } = useTickets();

  useEffect(() => {
    setData({
      dataGrid: getDateRange(
        ticketsTo ? formData?.date_return! : formData?.date_sent!
      ),
      tabValue: ticketsTo ? formData?.date_return! : formData?.date_sent!,
    });
  }, [formData?.date_return, formData?.date_sent, ticketsTo]);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    if (formData?.direction === "andBack") {
      if (!!ticketsTo) {
        setFormData({
          date_return: formattedDate,
        });
      } else {
        setFormData({
          date_sent: formattedDate,
          date_return: dayjs(formattedDate).add(1, "day").format("YYYY-MM-DD"),
        });
      }
    } else {
      setFormData({
        [`${ticketsTo ? "date_return" : "date_sent"}`]: formattedDate,
      });
    }
  };

  return (
    <TabWrapper elevation={1}>
      <TabContainer
        variant="fullWidth"
        value={data.tabValue}
        onChange={handleTabChange}
      >
        {data.dataGrid &&
          data.dataGrid.map((key) => {
            return (
              <TabElement
                key={nanoid()}
                disabled={
                  ticketsTo
                    ? dayjs(key).isBefore(
                        dayjs(formData?.date_sent).add(1, "day")
                      )
                    : dayjs(key).isBefore(dayjs(new Date()).subtract(1, "day"))
                }
                label={dayjs(key).locale(i18n.language).format("ddd, DD MMM.")}
                value={dayjs(key).format("YYYY-MM-DD")}
              />
            );
          })}
      </TabContainer>
    </TabWrapper>
  );
}

export default memo(DatePagination);
