import { styled } from "@mui/material/styles";
import { blue, deepOrange, grey, yellow } from "@mui/material/colors";
import { Button, Box, Typography, Grid, List, IconButton } from "@mui/material";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const ColorWrapper = styled(Box)({
  background:
    "linear-gradient(180deg, rgba(0,80,148,1) 0%, rgba(58,123,217,1) 100%)",
});

export const BuyTicketsContainer = styled(Box)(({ theme: { spacing } }) => ({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),

  "@media (min-width:600px)": {
    paddingTop: spacing(6),
  },
}));

export const BuyTicketsTitle = styled(Typography)(({ theme: { spacing } }) => ({
  fontWeight: 500,
  fontSize: 24,
  color: grey[100],
  textAlign: "center",
  marginBottom: spacing(2),

  "@media (min-width:600px)": {
    fontSize: 28,
    marginBottom: spacing(4),
  },
}));

export const SectionTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: PrimeTexrColor,
  fontWeight: 500,
  fontSize: 18,
  marginBottom: spacing(1),
  marginTop: spacing(6),
  "@media (min-width:600px)": {
    fontSize: 20,
    marginBottom: spacing(2),
    marginTop: spacing(10),
  },
  span: {
    color: blue[800],

    "&.yellow": {
      color: yellow[900],
    },
  },
}));

export const InfoContainer = styled(Grid)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
}));

export const MainFormWrapper = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(1),
  background: "#ffff",
  borderRadius: spacing(1),
}));

export const RelativAutocompleteWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const SwapWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  padding: 4,
  right: 0,
  zIndex: 1000,
  color: grey[500],
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: "100%",
  [breakpoints.up("md")]: {
    height: 41.44,
  },
}));

export const ListItemText = styled(Box)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 14,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 16,
  },
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  color: PrimeTexrColor,
  fontSize: 16,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 18,
  },
  span: {
    color: deepOrange[400],
  },
}));

export const AdvantagesContainer = styled(Grid)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
  marginTop: spacing(6),
}));

export const AdvantagesItem = styled(Grid)(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing(1),

    display: "flex",
    [breakpoints.down("sm")]: {
      flexDirection: "row",
    },
    [breakpoints.up("sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
    gap: spacing(1),
  })
);

export const AdvantagesTitle = styled(Typography)(
  ({ theme: { spacing, breakpoints } }) => ({
    color: PrimeTexrColor,
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 2,

    "@media (min-width:600px)": {
      fontSize: 16,
    },
  })
);

export const AdvantagesText = styled(Typography)(({ theme: { spacing } }) => ({
  color: grey[600],
  fontSize: 12,
  fontWeight: 400,
  "@media (min-width:600px)": {
    fontSize: 14,
  },
}));

export const StyledList = styled(List)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(1),
  padding: 4,
}));

export const StyledIconButton = styled(IconButton)(
  ({ theme: { spacing } }) => ({
    "&.MuiButtonBase-root": {
      padding: 0,
      background: "white",
    },
    "&.MuiIconButton-root": {
      padding: 0,
      background: "white",
    },
  })
);
