import { useTranslation } from "react-i18next";
import { ListItemLink } from "../style";
import { useLocation } from "react-router-dom";
import Link from "components/Link";

interface INavigateLinkItemProps {
  link: string;
  title: string;
}

export default function NavigateLinkItem({
  link,
  title,
}: INavigateLinkItemProps) {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <ListItemLink
      props={{
        isOnPathname: location.pathname === `/${i18n.language}${link}`,
      }}
    >
      <Link to={link}>{t(title)}</Link>
    </ListItemLink>
  );
}
