import css from "../Slider.module.css";

import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ReactNode } from "react";

interface ISliderProps {
  children: ReactNode;
}

export default function Slider({ children }: ISliderProps) {
  return (
    <Swiper
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        900: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
      // modules={[Autoplay]}
      className={css.swiper}
    >
      {children}
    </Swiper>
  );
}
