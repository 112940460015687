import { createSlice } from "@reduxjs/toolkit";
import { ICheckoutState } from "./types";

const initialState: ICheckoutState = {
  currentBus: null,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCurrentBus: (state, { payload }) => {
      state.currentBus = payload;
    },
    toggleSeats: (state, action) => {
      if (state.currentBus?.freeSeats) {
        const seatIndex = state.currentBus.freeSeats.findIndex(
          (seat) => seat.id === action.payload
        );

        if (seatIndex !== -1) {
          state.currentBus.freeSeats[seatIndex].isSelected =
            !state.currentBus.freeSeats[seatIndex].isSelected;
        }
      }
    },
  },
});

export const { setCurrentBus, toggleSeats } = checkoutSlice.actions;
export const checkoutReducer = checkoutSlice.reducer;
