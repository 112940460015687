import router from "./router";
import { RouterProvider } from "react-router-dom";
import ThemeProvider from "./theme/ThemeProvider";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider, useTranslation } from "react-i18next";
import ReduxProvider from "store/ReduxProvider";
import "dayjs/locale/uk";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/cs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { persistor } from "store/store";

function AppProvider() {
  const { i18n } = useTranslation();

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ReduxProvider>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language}
            >
              <ThemeProvider>
                <ToastContainer />
                <RouterProvider router={router} />
              </ThemeProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </I18nextProvider>
    </>
  );
}

export default AppProvider;
