import { createSlice } from "@reduxjs/toolkit";
import {   IPointsState } from "./types";
import { fetchPoints } from "./operation";

const initialState: IPointsState = {
  isLoading: false,
  error: null,
  points: null,
};

const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPoints.pending, (state: IPointsState) => {
        return { ...state, isLoading: true,error:null };
      })
      .addCase(fetchPoints.fulfilled, (state: IPointsState, { payload }) => {
        return { ...state, isLoading: false,error:null, points: payload  };
      })
      .addCase(fetchPoints.rejected, (state: IPointsState, { payload }) => {
        return { ...state, error: payload as string, isLoading: false };
      });
  },
});

export const pointsReducer = pointsSlice.reducer;
