import { IStore } from "store/store";

export const getisReady = (state: IStore) => state.auth.isReady;
export const getIsPageReloaded = (state: IStore) => state.auth.isPageReloaded;
export const getIsReadySignin = (state: IStore) => state.auth.isReadySignin;
export const getIsReadySendEmailVerified = (state: IStore) =>
  state.auth.isReadySendEmailVerified;
export const getIsSignedIn = (state: IStore) => state.auth.isSignedIn;
export const getIsReadyRegister = (state: IStore) => state.auth.isReadyRegister;
export const getIsReadyGoogle = (state: IStore) => state.auth.isReadyGoogle;
export const getIsReadyApple = (state: IStore) => state.auth.isReadyApple;
export const getIsReadyMicrosoft = (state: IStore) =>
  state.auth.isReadyMicrosoft;
export const getIsEmailVerified = (state: IStore) => state.auth.isEmailVerified;
export const getIsReadySendResetMail = (state: IStore) =>
  state.auth.isReadySendResetMail;
export const getIsReadyUpdateAuthUser = (state: IStore) =>
  state.auth.isReadyUpdateAuthUser;
export const getIsLoading = (state: IStore) => state.auth.isLoading;
export const getError = (state: IStore) => state.auth.error;
export const selectIsUpdated = (state: IStore) => state.auth.isUpdated;
