import { styled } from "@mui/material/styles";

import { Box } from "@mui/material";

export const CircularProgressWrapp = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));
