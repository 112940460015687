import { createSlice } from "@reduxjs/toolkit";
import { ISearchFormState } from "./types";
import dayjs from "dayjs";

const initialState: ISearchFormState = {
  city_from: "",
  city_to: "",
  date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
  date_return: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
  direction: "oneway",
  adult: 1,
  children: 0,
};

const searchFormSlice = createSlice({
  name: "searchForm",
  initialState,
  reducers: {
    setFormData: (state, { payload }) => {
      return { ...state, ...payload };
    },
    clearFormData: (state: ISearchFormState) => {
      return {
        city_from: "",
        city_to: "",
        date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
        date_return: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
        direction: "oneway",
        adult: 1,
        children: 0,
      };
    },
    incrementPassengers: (state, { payload }) => {
      switch (payload) {
        case "adult":
          return {
            ...state,
            adult: (state.adult || 0) + 1,
          };
        case "children":
          return {
            ...state,
            children: (state.children || 0) + 1,
          };
        default:
          return state;
      }
    },
    decrementPassengers: (state, { payload }) => {
      switch (payload) {
        case "adult":
          return {
            ...state,
            adult: (state.adult || 0) - 1,
          };
        case "children":
          return {
            ...state,
            children: (state.children || 0) - 1,
          };
        default:
          return state;
      }
    },
  },
});

export const {
  setFormData,
  clearFormData,
  incrementPassengers,
  decrementPassengers,
} = searchFormSlice.actions;

export const searchFormReducer = searchFormSlice.reducer;
