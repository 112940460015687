import { useEffect, useState } from "react";
import { Container, Toolbar, IconButton } from "@mui/material";
import SelectLang from "./components/SelectLang";
import ContactsMenu from "./components/ContactsMenu";
import BurgerMenu from "./BurgerMenu";
import { useMediaQuery, Theme } from "@mui/material";
import {
  AppBarContainer,
  LargeScreenWrapper,
  SmallScreenWrapper,
} from "./style";
import MenuIcon from "@mui/icons-material/Menu";
import SinginMenu from "./components/SinginMenu";
import LogoLink from "./components/LogoLink";

const Index = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );

  useEffect(() => {
    if (isSmallScreen) {
      setIsMenuOpen(false);
    }
  }, [isSmallScreen]);

  const toggleDrawer = () => {
    setIsMenuOpen((p) => !p);
  };

  return (
    <AppBarContainer position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <LogoLink />
          <SmallScreenWrapper>
 
          <ContactsMenu />
          <IconButton
              size="large"
              onClick={() => toggleDrawer()}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <BurgerMenu open={isMenuOpen} toggleDrawer={() => toggleDrawer()} />
          </SmallScreenWrapper>

          <LargeScreenWrapper>
            <SinginMenu />
            <SelectLang blackColor={false} />
            <ContactsMenu />
          </LargeScreenWrapper>
        </Toolbar>
      </Container>
    </AppBarContainer>
  );
};

export default Index;
