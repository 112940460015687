import CallIcon from "@mui/icons-material/Call";
import ua from "./images/ua.png";
import cz from "./images/cz.png";
import gb from "./images/gb.png";
import ru from "./images/ru.png";

import viber from "./images/menu-icon-contact-viber.png";
import telegram from "./images/menu-icon-contact-telegram.png";
import whatsapp from "./images/menu-icon-contact-whatsapp.png";
import fb from "./images/menu-icon-contact-fb.png";

export interface ContactItem {
  icon: JSX.Element | string;
  link: string;
  label: string;
}

export interface IlangData {
  src: string;
  width: number;
  height: number;
  label: string;
}

export const contactData: ContactItem[] = [
  {
    icon: <CallIcon color="action" fontSize="small" />,
    link: `tel:${+380996033832}`,
    label: "+380996033832",
  },
  {
    icon: <CallIcon color="action" fontSize="small" />,
    link: `tel:${+380983619098}`,
    label: "+380983619098",
  },
  {
    icon: <CallIcon color="action" fontSize="small" />,
    link: `tel:${+380980823192}`,
    label: "+380980823192",
  },
  {
    icon: viber,
    link: `viber://chat?number=${+380983619098}`,
    label: "Viber",
  },
  {
    icon: telegram,
    link: "https://t.me/EAST_EXPRESS_tiket_ua",
    label: "Telegram",
  },
  {
    icon: whatsapp,
    link: `https://api.whatsapp.com/send?phone=${+380983619098}`,
    label: "Whatsapp",
  },
  {
    icon: fb,
    link: "https://www.facebook.com/share/M4xwUaVdvEV87eVr/?mibextid=LQQJ4d",
    label: "Facebook",
  },
];

export const langData: IlangData[] = [
  {
    src: ua,
    width: 20,
    height: 15,
    label: "uk",
  },
  {
    src: cz,
    width: 20,
    height: 15,
    label: "cz",
  },
  {
    src: gb,
    width: 20,
    height: 15,
    label: "en",
  },
  {
    src: ru,
    width: 20,
    height: 15,
    label: "ru",
  },
];
export const pagesRoutes = [
  { title: "frequentlyAskedQuestions", link: "/faq" },
  { title: "news", link: "/news" },
  { title: "aboutUs", link: "/aboutus" },
  { title: "contacts", link: "/contacts" },
];
