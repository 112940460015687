import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

export const MainWrapper = styled(Box)(({ theme: { spacing } }) => ({
  flex: "1  0 auto",
  background: "#f5f5f5",
  paddingBottom: spacing(8),
  "@media (min-width:600px)": {
    paddingBottom: spacing(15),
  },
}));
