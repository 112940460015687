import { IUseBuses } from "./types";
import {
  selectBusesData,
  selectError,
  selectIsLoading,
  selectClosestBus,
  selectClosestBusError,
} from "./selector";
import { useSelector } from "react-redux";
import { getBus } from "./operation";
import { useAppDispatch } from "store/hooks";

export const useBuses = (): IUseBuses => {
  const isLoading = useSelector(selectIsLoading);
  const busesData = useSelector(selectBusesData);
  const busesError = useSelector(selectError);
  const closestBus = useSelector(selectClosestBus);
  const closestError = useSelector(selectClosestBusError);
  const lastError = useSelector(selectClosestBusError);

  const dispatch = useAppDispatch();

  return {
    isLoading,
    busesData,
    busesError,
    closestBus,
    closestError,
    lastError,
    getBuses: (data) => {
      dispatch(getBus(data));
    },
  };
};
