import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { getIsopenModal, getLoginModal } from "./selector";
import { handleClose, handleOpen, toggleForm } from "./slice";
import { revoke } from "features/auth/slice";
import { useAuth } from "features/auth/useAuth";

interface IUseModal {
  isOpen: boolean;
  isLoginForm: boolean;
  closeModal: () => void;
  openModal: () => void;
  toggleForm: () => void;
}

export const useModal = (): IUseModal => {
  const { isLoading } = useAuth();
  const isOpen = useSelector(getIsopenModal);
  const isLoginForm = useSelector(getLoginModal);

  const dispatch = useAppDispatch();

  return {
    isOpen,
    isLoginForm,
    closeModal: () => {
      if (!isLoading) {
        dispatch(handleClose());
        dispatch(revoke());
      }
    },
    openModal: () => {
      dispatch(handleOpen());
    },
    toggleForm: () => {
      if (!isLoading) {
        dispatch(revoke());
        dispatch(toggleForm());
      }
    },
  };
};
