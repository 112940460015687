import { createSlice } from "@reduxjs/toolkit";
import { ITickets } from "./types";
 
const initialState: ITickets = {
  ticketsTo: null,
  ticketsFrom: null,
  selectTicket: false,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketsTo: (state, { payload }) => {
      state.ticketsTo = payload;
    },
    setTicketsFrom: (state, { payload }) => {
      state.ticketsFrom = payload;
    },
    setTicket: (state, { payload }) => {
      state.selectTicket = payload;
    },
    clearTickets: (state) => {
      state.ticketsTo = null;
      state.ticketsFrom = null;
    },
    clearTicket: (state) => {
      state.selectTicket = false;
    },
  },
});

export const {
  setTicketsTo,
  setTicketsFrom,
  setTicket,
  clearTickets,
  clearTicket,
} = ticketsSlice.actions;
export const ticketsReducer = ticketsSlice.reducer;
