import Footer from "components/Footer";
import NavBar from "components/NavBar";
import Points from "components/Points";
import User from "components/User";
import { MainWrapper, Wrapper, Title, ErrorContainer } from "./style";
import { Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Seo from "components/Seo";

export default function ErrorPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Seo title={"seo.error.title"} description={"seo.error.description"} />

      <User />
      <Points />
      <Wrapper>
        <NavBar />
        <MainWrapper component="main">
          <Container maxWidth="lg">
            <ErrorContainer>
              <Title>{t("an_error_occurred")}</Title>
              <Button
                variant="outlined"
                color="error"
                size="large"
                onClick={() => navigate(`/${i18n.language}`, { replace: true })}
              >
                {t("main")}
              </Button>
            </ErrorContainer>
          </Container>
        </MainWrapper>
        <Footer />
      </Wrapper>
    </>
  );
}
