import { usePoints } from "features/points/usePoints";
import { memo, useEffect } from "react";

function Points() {
  const { getPoints } = usePoints();
  useEffect(() => {
    getPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default memo(Points);
