import { SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { nanoid } from "nanoid";

import { SectionTitle } from "../style";
import { useNews } from "features/news/useNews";
import { useEffect } from "react";
import NewsCard from "components/NewsCard";
import Slider from "./Slyder";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Link from "components/Link";

export default function NewsSlider() {
  const { newsData, fetchNews, fetchCurrentNews, revoke, loadingNews } =
    useNews();
  const { t } = useTranslation();

  useEffect(() => {
    fetchNews();
    return () => {
      revoke();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SectionTitle>{t("news")}</SectionTitle>
      <>
        {!loadingNews && (
          <Slider>
            {newsData &&
              Object.keys(newsData).map((id) => {
                const e = newsData[id];

                return (
                  e && (
                    <SwiperSlide key={nanoid()} className=".swiperSlide">
                      <Link
                        to={`/news`}
                        onClick={() => fetchCurrentNews(e.docId)}
                      >
                        <NewsCard news={e} />
                      </Link>
                    </SwiperSlide>
                  )
                );
              })}
          </Slider>
        )}
        {loadingNews && (
          <Slider>
            {[1, 2, 3].map((id) => (
              <SwiperSlide key={nanoid()} className=".swiperSlide">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={380}
                  sx={{ borderRadius: 2 }}
                />
              </SwiperSlide>
            ))}
          </Slider>
        )}
      </>
    </>
  );
}
