import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion } from "@mui/material";
import { ReactNode } from "react";
import {
  AccordionSummaryList,
  AccordionSummaryTitle,
  BurgerAccordionSummary,
} from "../style";

interface IBurgerMenuAccordionProps {
  children: ReactNode;
  title: string;
  icon: ReactNode;
}

export default function BurgerMenuAccordion({
  children,
  title,
  icon,
}: IBurgerMenuAccordionProps) {
  return (
    <Accordion disableGutters elevation={0}>
      <BurgerAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {icon}
        <AccordionSummaryTitle>{title}</AccordionSummaryTitle>
      </BurgerAccordionSummary>
      <AccordionSummaryList>{children}</AccordionSummaryList>
    </Accordion>
  );
}
