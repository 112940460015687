import { Box, ListItem } from "@mui/material";
import { useModal } from "features/modal/useModal";
import { useTranslation } from "react-i18next";
import LoginIcon from "@mui/icons-material/Login";
import { AuthLinkText } from "../style";

interface INotSinginItemsProps {
  handleClose: () => void;
}
export default function NotSinginItems({ handleClose }: INotSinginItemsProps) {
  const { openModal } = useModal();
  const { t } = useTranslation();

  return (
    <ListItem>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 16px",
          "@media (min-width:600px)": {
            padding: "10px 16px",
          },
        }}
        onClick={() => {
          handleClose();
          openModal();
        }}
      >
        <LoginIcon fontSize="small" />
        <AuthLinkText>{t("signInRegister")}</AuthLinkText>
      </Box>
    </ListItem>
  );
}
