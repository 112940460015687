import { Box, Grid } from "@mui/material";
import { useTickets } from "features/tickets/useTickets";
import { useBuses } from "features/buses/useBuses";
import { useSearchForm } from "features/searchform/useShopForm";
import { busesResultCount } from "components/CommonForms";
import { SearchResultCount, SearchResultGrid, SearchResultText } from "./style";
import { useTranslation } from "react-i18next";

export default function SearchResult() {
  const { ticketsTo } = useTickets();
  const { busesData } = useBuses();
  const { formData } = useSearchForm();
  const { t } = useTranslation();

  return (
    <SearchResultGrid container>
      <Grid item xs={6}>
        {formData?.direction !== "oneway" ? (
          <SearchResultText>
            {!!ticketsTo ? t("return_journey") : t("journey_destination")}
          </SearchResultText>
        ) : (
          <Box>
            <SearchResultText>{t("bus_tickets")}</SearchResultText>
            <SearchResultText>
              {`${formData?.city_from} - ${formData?.city_to}`}
            </SearchResultText>
          </Box>
        )}
      </Grid>

      <Grid item xs={6} sx={{ textAlign: "end" }}>
        <SearchResultCount>
          {`${t("found")} ${busesResultCount(busesData)} `}
        </SearchResultCount>
      </Grid>
    </SearchResultGrid>
  );
}
