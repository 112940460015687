import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "services/firebase";
import { IFetchReportsByQueryProps, ITticket, IUser } from "./types";
import { setAgents, setTickets } from "./slice";

export const fetchAgents = createAsyncThunk(
  "reports/fetchAgents",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const ordersRoleQuery = query(
        collection(firestore, "usersInfo"),
        where("role", "==", "agent")
      );
      const data = await getDocs(ordersRoleQuery);

      const agentData: string[] = data.docs.map((doc) => {
        const userData = doc.data() as IUser;
        return userData.email;
      });
      dispatch(setAgents(agentData));
      return null;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchReportsByQuery = createAsyncThunk(
  "reports/fetchReportsByQuery",
  async (
    { email, start_date, end_date }: IFetchReportsByQueryProps,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const searchQuery =
        email === ""
          ? query(
              collection(firestore, "tickets"),
              where("departureDate", ">=", start_date),
              where("departureDate", "<=", end_date),
              orderBy("departureDate")
            )
          : query(
              collection(firestore, "tickets"),
              where("departureDate", ">=", start_date),
              where("departureDate", "<=", end_date),
              where("userEmail", "==", email),
              orderBy("departureDate")
            );

      const reportsSnapshot = await getDocs(searchQuery);

      const ticketsData: ITticket[] = reportsSnapshot.docs.map(
        (doc) => doc.data() as ITticket
      );

 
      dispatch(setTickets(ticketsData));

      return null;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
