import { Container } from "@mui/material";
import { SkeletonContainer, StyledSkeleton } from "./style";

export default function Skeleton() {
  return (
    <Container maxWidth="md">
      <SkeletonContainer>
        <StyledSkeleton variant="rectangular" width="100%" height={48} />
        <StyledSkeleton variant="rectangular" width={122} height={40} />
        <StyledSkeleton variant="rectangular" width="100%" height={203} />
      </SkeletonContainer>
    </Container>
  );
}
