import { useUser } from "features/user/useUser";
import HomePage from "pages/Main";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";

export default function ProtectedRoute() {
  const { user } = useUser();
  const location = useLocation();
  const [, setLocationRoles] = useState(null);
  const getRouteIdFromOutlet = useOutlet();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const routeLocationData =
      getRouteIdFromOutlet?.props.children.props.match.route;
    const locationKey = routeLocationData?.path;

    const locationRolesObj =
      routeLocationData?.id && JSON.parse(routeLocationData?.id);

    if (locationRolesObj && locationKey) {
      setLocationRoles(locationRolesObj[locationKey]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location.pathname]);

  useEffect(() => {
    if (!user) {
      navigate(`/${i18n.language}`, { replace: true });
    }
  }, [user, navigate, i18n.language]);

  return user ? <Outlet key={location.pathname} /> : <HomePage />;
}
