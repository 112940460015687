import { createSlice } from "@reduxjs/toolkit";
import { IReportsState } from "./types";
import { fetchAgents, fetchReportsByQuery } from "./operation";

const initialState: IReportsState = {
  loadingReports: false,
  error: null,
  loadingAgents: false,
  agents: null,
  tickets: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setAgents: (state: IReportsState, { payload }) => {
      return { ...state, agents: payload };
    },

    setTickets: (state: IReportsState, { payload }) => {
      return { ...state, tickets: payload };
    },

    revoke: (state) => {
      return {
        ...state,
        loadingAgents: false,
        tickets: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state, { payload }) => {
        return { ...state, loadingAgents: true };
      })
      .addCase(fetchAgents.fulfilled, (state, { payload }) => {
        return { ...state, loadingAgents: false };
      })
      .addCase(fetchAgents.rejected, (state, { payload }) => {
        return { ...state, loadingAgents: false, error: payload as string };
      })
      .addCase(fetchReportsByQuery.pending, (state, { payload }) => {
        return { ...state, loadingReports: true };
      })
      .addCase(fetchReportsByQuery.fulfilled, (state, { payload }) => {
        return { ...state, loadingReports: false };
      })
      .addCase(fetchReportsByQuery.rejected, (state, { payload }) => {
        return { ...state, loadingReports: false, error: payload as string };
      });
  },
});

export const { revoke, setTickets, setAgents } = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
