import { useSelector } from "react-redux";

import {
  getError,
  getIsEmailVerified,
  getIsLoading,
  getIsPageReloaded,
  getIsReadyApple,
  getIsReadyGoogle,
  getIsReadyMicrosoft,
  getIsReadyRegister,
  getIsReadySendEmailVerified,
  getIsReadySendResetMail,
  getIsReadySignin,
  getIsReadyUpdateAuthUser,
  getIsSignedIn,
  getisReady,
} from "./selector";
import { IUseAuth } from "./types";
import {
  register,
  signinEmailPassword,
  signinGoogle,
  signout,
} from "./operation";
import { revoke } from "./slice";
import { useAppDispatch } from "store/hooks";

export const useAuth = (): IUseAuth => {
  const dispatch = useAppDispatch();

  const isReady = useSelector(getisReady);
  const isPageReloaded = useSelector(getIsPageReloaded);
  const isReadySignin = useSelector(getIsReadySignin);
  const isReadySendEmailVerified = useSelector(getIsReadySendEmailVerified);
  const isSignedIn = useSelector(getIsSignedIn);
  const isReadyRegister = useSelector(getIsReadyRegister);
  const isReadyGoogle = useSelector(getIsReadyGoogle);
  const isReadyApple = useSelector(getIsReadyApple);
  const isReadyMicrosoft = useSelector(getIsReadyMicrosoft);
  const isEmailVerified = useSelector(getIsEmailVerified);
  const isReadySendResetMail = useSelector(getIsReadySendResetMail);
  const isReadyUpdateAuthUser = useSelector(getIsReadyUpdateAuthUser);
  const isLoading = useSelector(getIsLoading);
  const isUpdated = useSelector(getIsLoading);

  const error = useSelector(getError);

  return {
    isUpdated,
    isLoading,
    isReady,
    isPageReloaded,
    isReadySignin,
    isReadySendEmailVerified,
    isSignedIn,
    isReadyRegister,
    isReadyGoogle,
    isReadyApple,
    isReadyMicrosoft,
    isEmailVerified,
    isReadySendResetMail,
    isReadyUpdateAuthUser,
    error,

    signinEmailPassword: (signinForm) => {
      dispatch(signinEmailPassword(signinForm));
    },
    signinGoogle: () => {
      dispatch(signinGoogle());
    },
    signOut: () => {
      dispatch(signout());
    },

    register: (registerForm) => {
      dispatch(register(registerForm));
    },

    revoke: () => {
      dispatch(revoke());
    },
  };
};
