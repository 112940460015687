import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";

import { auth, firestore } from "services/firebase";
import { updateUser } from "../user/operation";
import { Timestamp, doc, getDoc } from "firebase/firestore";
import { IStore } from "store/store";
import { USERS } from "services";
import { IRegisterForm, ISigninEmail } from "./types";
import handleError from "../handleError";
import { revokeUser } from "features/user/slice";
import { handleClose } from "features/modal/slice";

export const register = createAsyncThunk(
  "auth/register",
  async (
    { email, password, name, surname, phone, displayName }: IRegisterForm,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (user === null) {
        return rejectWithValue("auth.NO_USER");
      }

      try {
        await updateProfile(user, { displayName });
      } catch (error: any) {
        return rejectWithValue("auth.NO_USER");
      }

      dispatch(
        updateUser({
          userId: user.uid,
          data: {
            createdDate: Timestamp.now(),
            deleted: false,
            name: name,
            surname: surname,
            email: email,
            platform: "web",
            emailVerified: false,
            updated: Timestamp.now(),
            phone: phone,
            version: 1,
          },
        })
      );
      dispatch(handleClose());
    } catch (error: any) {
      handleError(error.code, error);
      return rejectWithValue(error.code);
    }
  }
);

export const signinEmailPassword = createAsyncThunk(
  "auth/signinEmailPassword",
  async ({ email, password }: ISigninEmail, { dispatch, rejectWithValue }) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        dispatch(handleClose());
      }
      return response;
    } catch (error: any) {
      return rejectWithValue(error.code);
    }
  }
);

export const signout = createAsyncThunk(
  "auth/signout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await auth.signOut();
      dispatch(revokeUser());
    } catch (error: any) {
      handleError(error.code, error);
      return rejectWithValue(error.code);
    }
  }
);

export const setSigninFirebase = createAsyncThunk(
  "auth/signinFirebase",
  async (user: any, { rejectWithValue, getState, dispatch }) => {
    const state = getState() as IStore;

    if (
      !state.auth.isReadyRegister ||
      !state.auth.isReadyGoogle ||
      !state.auth.isReadyApple ||
      !state.auth.isReadyMicrosoft
    ) {
      return;
    }

    try {
      const docRef = doc(firestore, USERS, user.uid || "");
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();

      if (userData) {
        dispatch(updateUser({ userId: user.uid || "", data: { ...userData } }));
      } else {
      }
    } catch (error: any) {
      handleError(error.code, error);
      return rejectWithValue(error.code);
    }
  }
);

export const signinGoogle = createAsyncThunk(
  "auth/signinGoogle",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      if (result !== null) {
        const {
          user: { uid, email, photoURL, displayName },
        } = result;

        dispatch(
          updateUser({
            userId: uid,
            data: {
              createdDate: Timestamp.now(),
              deleted: false,
              email,
              emailVerified: true,
              photoURL,
              platform: "web",
              surname:
                (displayName &&
                  displayName.includes(" ") &&
                  displayName.split(" ")[1]) ||
                "",

              name:
                (displayName &&
                  displayName.includes(" ") &&
                  displayName.split(" ")[0]) ||
                "",
            },
          })
        );
        dispatch(handleClose());
      }
    } catch (error: any) {
      handleError(error.code);
      return rejectWithValue(error.code);
    }
  }
);
