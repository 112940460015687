import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const stops = httpsCallable(functions, "getStops");
export const bus = httpsCallable(functions, "getBus");
export const newOrder = httpsCallable(functions, "createOrder");
export const setPayment = httpsCallable(functions, "setPayment");
export const declinePayment = httpsCallable(functions, "declinePayment");
export const checkPaymentStatus = httpsCallable(functions, "checkPaymentStatus");
export const getTicketPDF = httpsCallable(functions, "getTicketPDF");
export const getReportPDF = httpsCallable(functions, "getReportPDF");
export const getClosestBus = httpsCallable(functions, "getClosestBus");



