import {
  getIsloading,
  getRole,
  getUserData,
  getUserError,
  getUserId,
} from "./selector";
import { IUser, TRole } from "./types";
import { useSelector } from "react-redux";
import { updateUser } from "./operation";
import { useAppDispatch } from "store/hooks";

interface IUseAuth {
  error: string | null;
  user: IUser | null;
  role: TRole | undefined;
  isLoading: boolean;
  update: (data: IUser) => void;
}

export const useUser = (): IUseAuth => {
  const error = useSelector(getUserError);
  const user = useSelector(getUserData);
  const id = useSelector(getUserId);
  const role = useSelector(getRole);

  const isLoading = useSelector(getIsloading);

  const dispatch = useAppDispatch();

  return {
    error,
    role,
    user,
    isLoading,
    update: (data: IUser) => {
      if (id) {
        dispatch(updateUser({ userId: id, data }));
      }
    },
  };
};
