import {
  AdvantagesContainer,
  AdvantagesItem,
  AdvantagesText,
  AdvantagesTitle,
} from "../style";

import free from "../images/free.png";
import monitor from "../images/monitor.png";
import starlink from "../images/starlink.png";
import creditCart from "../images/icon-credit.png";

import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Advantages() {
  const { t } = useTranslation();
  return (
    <AdvantagesContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AdvantagesItem>
            <img src={free} alt="free" width={40} height={40} />
            <Box>
              <AdvantagesTitle>{t("freeBooking")}</AdvantagesTitle>
              <AdvantagesText>{t("freeBookingOffer")}</AdvantagesText>
            </Box>
          </AdvantagesItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AdvantagesItem>
            <img src={monitor} alt="monitor" width={40} height={40} />
            <Box>
              <AdvantagesTitle>{t("noQueue")}</AdvantagesTitle>
              <AdvantagesText>{t("noQueueOffer")}</AdvantagesText>
            </Box>
          </AdvantagesItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AdvantagesItem>
            <img src={starlink} alt="monitor" width={40} height={40} />
            <Box>
              <AdvantagesTitle>{t("starlinkInternet")}</AdvantagesTitle>
              <AdvantagesText>{t("starlinkInternetOffer")}</AdvantagesText>
            </Box>
          </AdvantagesItem>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AdvantagesItem>
            <img src={creditCart} alt="credit-cart" width={40} height={40} />
            <Box>
              <AdvantagesTitle>{t("convenientPayment")}</AdvantagesTitle>
              <AdvantagesText>{t("convenientPaymentOffer")}</AdvantagesText>
            </Box>
          </AdvantagesItem>
        </Grid>
      </Grid>
    </AdvantagesContainer>
  );
}
