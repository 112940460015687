import { useSelector } from "react-redux";
import { IUseShopForm } from "./types";
import { getAdultCount, getChildrenCount, getSearchFormData } from "./selector";
import {
  clearFormData,
  decrementPassengers,
  incrementPassengers,
  setFormData,
} from "./slice";
import { useAppDispatch } from "store/hooks";

export const useSearchForm = (): IUseShopForm => {
  const formData = useSelector(getSearchFormData);
  const childrenCount = useSelector(getChildrenCount) || 0;
  const adultCount = useSelector(getAdultCount) || 1;

  const dispatch = useAppDispatch();

  return {
    formData,
    adultCount,
    childrenCount,
    incrementPassengers: (direction: string) => {
      dispatch(incrementPassengers(direction));
    },
    decrementPassengers: (direction: string) => {
      dispatch(decrementPassengers(direction));
    },
    setFormData: (data) => {
      dispatch(setFormData(data));
    },
    clearFormData: () => {
      dispatch(clearFormData());
    },
  };
};
