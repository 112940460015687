import { ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LinkBox } from "../style";
import Link from "components/Link";

interface INavigateLinkProps {
  onClick: () => void;
  href: string;
  title: string;
}

export default function NavigateLink({
  href,
  onClick,
  title,
}: INavigateLinkProps) {
  const { t } = useTranslation();

  return (
    <ListItem>
      <LinkBox>
        <Link to={href} onClick={() => onClick()}>
          {t(title)}
        </Link>
      </LinkBox>
    </ListItem>
  );
}
