import { Grid, ListItem } from "@mui/material";
import {
  AdvantagesText,
  AdvantagesTitle,
  InfoContainer,
  SectionTitle,
  StyledList,
} from "../style";
import { useTranslation } from "react-i18next";

export default function InfoSection() {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>
        {t("whyChoose")}
        <span>{t("East")}</span>
        <span className="yellow">{t("Express")}</span>
      </SectionTitle>

      <InfoContainer container spacing={1}>
        <Grid item xs={12} md={4}>
          <StyledList>
            <ListItem>
              <AdvantagesTitle>{t("buyOnlineEasily")}</AdvantagesTitle>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("buyOnlineEasilyOffer_1")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("buyOnlineEasilyOffer_2")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("buyOnlineEasilyOffer_3")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("buyOnlineEasilyOffer_4")}</AdvantagesText>
            </ListItem>
          </StyledList>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledList>
            <ListItem>
              <AdvantagesTitle>{t("freeReservation")}</AdvantagesTitle>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("freeReservationOffer_1")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("freeReservationOffer_2")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("freeReservationOffer_3")}</AdvantagesText>
            </ListItem>
          </StyledList>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledList>
            <ListItem>
              <AdvantagesTitle>{t("easyTicketReturn")}</AdvantagesTitle>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("easyTicketReturnOffer_1")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("easyTicketReturnOffer_2")}</AdvantagesText>
            </ListItem>
            <ListItem>
              <AdvantagesText>{t("easyTicketReturnOffer_3")}</AdvantagesText>
            </ListItem>
          </StyledList>
        </Grid>
      </InfoContainer>
    </>
  );
}
