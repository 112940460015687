import { INews } from "features/news/types";
import { Box, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  NewsCardContent,
  PreviewNewsCardImage,
  PreviewNewsCardTitle,
  NewsCardContainer,
} from "./style";
import CartMeta from "./CartMeta";
import { extractNewsData } from "./helpers";
import { useNews } from "features/news/useNews";
import LoadEditBtn from "./LoadEditBtn";
import dayjs from "dayjs";

interface PreviewNewsCardProps {
  news: INews;
  isAdmin?: boolean;
}

export default function NewsCard({ news, isAdmin }: PreviewNewsCardProps) {
  const { title, image } = extractNewsData(news.text);
  const { deleteNewsById, сurrentNews } = useNews();
  return (
    <NewsCardContainer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PreviewNewsCardImage>
            <img src={image} alt={title} />
          </PreviewNewsCardImage>
        </Grid>
        <Grid item xs={12}>
          <NewsCardContent>
            <PreviewNewsCardTitle as={"h2"}>{title}</PreviewNewsCardTitle>
            <CartMeta
              date={dayjs(news?.date?.seconds! * 1000).format("DD.MM.YYYY")}
            />
            {isAdmin && (
              <Box>
                <LoadEditBtn docId={news.docId} />
                <IconButton
                  color="error"
                  disabled={news.docId === сurrentNews?.docId}
                  onClick={() => {
                    deleteNewsById(news.docId);
                  }}
                  size="medium"
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              </Box>
            )}
          </NewsCardContent>
        </Grid>
      </Grid>
    </NewsCardContainer>
  );
}
