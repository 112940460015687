import { useField } from "formik";
import { memo } from "react";
import { MuiTelInput } from "mui-tel-input";

interface ISimplePhoneFieldProps {
  name: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

const SimplePhoneField = ({
  name,
  isDisabled,
  isRequired,
}: ISimplePhoneFieldProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <MuiTelInput
      {...field}
      size="small"
      fullWidth
      forceCallingCode
      defaultCountry="UA"
      disableFormatting
      disabled={isDisabled}
      onlyCountries={["UA", "CZ", "PL", "DE", "BE"]}
      id={name}
      onChange={(newValue: string) => {
        helpers.setValue(newValue);
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      required={isRequired}
    />
  );
};

export default memo(SimplePhoneField);
