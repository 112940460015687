import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  signinEmailPassword,
  signout,
  setSigninFirebase,
  signinGoogle,
} from "./operation";
import { IAuth } from "./types";

const initialState: IAuth = {
  isLoading: false,
  isReady: false,
  isPageReloaded: true,
  isReadySignin: false,
  isReadySendEmailVerified: false,
  isSignedIn: false,
  error: null,
  isReadyRegister: true,
  isReadyGoogle: true,
  isReadyApple: true,
  isReadyMicrosoft: true,
  isEmailVerified: true,
  isReadySendResetMail: true,
  isReadyUpdateAuthUser: true,
  isUpdated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    revoke: (state: IAuth) => {
      return { ...state, error: null, isLoading: false };
    },
    setNotSignedIn: (state: IAuth) => {
      return {
        ...state,
        isReadySignin: true,
        isSignedIn: false,
        isLoading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signout.pending, (state: IAuth, action) => {
        return { ...state, isLoading: true };
      })
      .addCase(signout.fulfilled, (state: IAuth, action) => {
        return { ...state, isSignedin: false, isLoading: false };
      })
      .addCase(signout.rejected, (state: IAuth, action) => {
        return { ...state, error: action.error, isLoading: false };
      })
      .addCase(register.pending, (state: IAuth, action) => {
        return { ...state, isLoading: true };
      })
      .addCase(register.fulfilled, (state: IAuth, action) => {
        return !state.error
          ? {
              ...state,
              isEmailVerified: false,
              isReadyRegister: true,
              isSignedIn: true,
              error: null,
              isLoading: false,
            }
          : { ...state };
      })
      .addCase(register.rejected, (state: IAuth, action) => {
        return {
          ...state,
          error: action.payload,
          isReadyRegister: true,
          isLoading: false,
        };
      })
      .addCase(signinEmailPassword.pending, (state: IAuth, action) => {
        return {
          ...state,
          isReadySignin: false,
          isSignedIn: false,
          error: null,
          isLoading: true,
        };
      })
      .addCase(signinEmailPassword.fulfilled, (state: IAuth, action) => {
        return !state.error
          ? {
              ...state,
              isReadySignin: true,
              isSignedIn: true,
              error: null,
              isLoading: false,
            }
          : { ...state };
      })
      .addCase(signinEmailPassword.rejected, (state: IAuth, action) => {
       
        return {
          ...state,
          isReadySignin: true,
          isSignedIn: false,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(setSigninFirebase.pending, (state: IAuth, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(setSigninFirebase.fulfilled, (state: IAuth, action) => {
        return !state.error
          ? {
              ...state,
              isEmailVerified: false,
              isReadySignin: true,
              isSignedIn: true,
              isPageReloaded: false,
              error: null,
              isLoading: false,
            }
          : { ...state };
      })
      .addCase(setSigninFirebase.rejected, (state: IAuth, action) => {
        return {
          ...state,
          isPageReloaded: false,
          isReadySignin: false,
          isSignedIn: false,
          error: action.payload,
          isLoading: false,
        };
      })
      .addCase(signinGoogle.pending, (state: IAuth, action) => {
        return {
          ...state,
          isReadyGoogle: false,
          isLoading: true,
        };
      })
      .addCase(signinGoogle.fulfilled, (state: IAuth, action) => {
        return {
          ...state,
          isReadyGoogle: true,
          isSignedIn: true,
          isLoading: false,
        };
      })
      .addCase(signinGoogle.rejected, (state: IAuth, action) => {
        return {
          ...state,
          isReadyGoogle: true,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});
export const { revoke, setNotSignedIn } = authSlice.actions;
export const authReducer = authSlice.reducer;
