import Footer from "components/Footer";
import NavBar from "components/NavBar";

import { MainWrapper, Wrapper } from "components/MainLayout/style";
import { ReactNode } from "react";

interface IFirstLayout {
  children: ReactNode;
}

export default function FirstLayout({ children }: IFirstLayout) {
  return (
    <Wrapper>
      <NavBar />
      <MainWrapper component="main">{children}</MainWrapper>
      <Footer />
    </Wrapper>
  );
}
