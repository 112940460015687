import { createSlice } from "@reduxjs/toolkit";
import { IUserState, TError } from "./types";
import { updateUser } from "./operation";

export const initialState: IUserState = {
  error: null,
  data: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    revokeUser: (state: IUserState) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state: IUserState, action) => {
        return { ...state, isLoading: true };
      })
      .addCase(updateUser.fulfilled, (state: IUserState, action) => {
        return {
          ...state,
          data: {
            ...state.data,
            ...action.payload,
          },
          isLoading: false,
        };
      })
      .addCase(updateUser.rejected, (state: IUserState, action: TError) => {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      });
  },
});
export const { revokeUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
