import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const PrimeTexrColor = `rgb(66, 69, 67)`;

export const BuyTicketsContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: 24,
  color: PrimeTexrColor,

  "@media (min-width:600px)": {
    fontSize: 28,
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
  flex: "1  0 auto",
}));

export const ErrorContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(15),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: spacing(2),
  "@media (min-width:600px)": {},
}));
