import { ListItem } from "@mui/material";
import { useAuth } from "features/auth/useAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import { useUser } from "features/user/useUser";
import { useTranslation } from "react-i18next";
import { AuthLink, AuthLinkText } from "../style";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { nanoid } from "nanoid";

interface ISinginItemsProps {
  handleClose: () => void;
}

export default function SinginItems({ handleClose }: ISinginItemsProps) {
  const { role } = useUser();
  const { t, i18n } = useTranslation();
  const { signOut } = useAuth();

  return (
    <>
      <ListItem key={nanoid()}>
        <AuthLink
          to={role ? `/${i18n.language}/${role}` : `/${i18n.language}`}
          onClick={() => {
            handleClose();
          }}
        >
          <ManageAccountsIcon fontSize="small" />
          <AuthLinkText>{t("myProfile")}</AuthLinkText>
        </AuthLink>
      </ListItem>

      <ListItem key={nanoid()}>
        <AuthLink
          to={`/${i18n.language}`}
          replace
          onClick={() => {
            handleClose();
            signOut();
          }}
        >
          <LogoutIcon fontSize="small" />
          <AuthLinkText>{t("signOut")}</AuthLinkText>
        </AuthLink>
      </ListItem>
    </>
  );
}
