import { Box, Container, Divider } from "@mui/material";
import InfoSection from "./components/InfoSection";
import BuyTickets from "./components/BuyTickets";
import { ColorWrapper } from "./style";
import BusRoutes from "components/BusRoutes";
import Advantages from "./components/Advantages";
import NewsSlider from "./components/NewsSlider";
import { useEffect } from "react";
import { useSearchForm } from "features/searchform/useShopForm";
import dayjs from "dayjs";

export default function HomePage() {
  const { setFormData } = useSearchForm();

  useEffect(() => {
    setFormData({
      date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
      date_return: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box component="section">
        <ColorWrapper>
          <Container maxWidth="lg">
            <BuyTickets />
          </Container>
        </ColorWrapper>
      </Box>

      <Box component="section">
        <Container maxWidth="lg">
          <Advantages />
        </Container>
      </Box>

      <Box component="section">
        <Container maxWidth="lg">
          <InfoSection />
        </Container>
      </Box>

      <Box component="section">
        <Container maxWidth="lg">
          <NewsSlider />
        </Container>
      </Box>

      <Divider />
      <Box component="section">
        <Container maxWidth="lg">
          <BusRoutes />
        </Container>
      </Box>
    </>
  );
}
