import { Grid } from "@mui/material";
import {
  MainFormWrapper,
  RelativAutocompleteWrapper,
  StyledButton,
  SwapWrapper,
} from "../style";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSearchForm } from "features/searchform/useShopForm";
import { MouseEvent, useEffect, useMemo, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import {
  CityPicker,
  DatePicker,
  DirectionRadioBtn,
  PassengersCount,
} from "components/CommonForms";
import { IFormInitialValues } from "../types";
import { useTickets } from "features/tickets/useTickets";
import SwapBtn from "components/CommonForms/SwapBtn";
import BackDiscount from "components/BackDiscount";

const validationSchema = yup.object({
  city_from: yup.string().required(),
  city_to: yup.string().required(),
});

export default function MainForm() {
  const { clearTickets } = useTickets();
  const { formData, adultCount, childrenCount, setFormData } = useSearchForm();
  const formikRef = useRef<FormikProps<IFormInitialValues> | null>();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const initialValues: IFormInitialValues = useMemo(() => {
    return {
      direction: "oneway",
      city_from: "",
      city_to: "",
      date_sent: dayjs(new Date()).format("YYYY-MM-DD"),
      date_return: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
    };
  }, []);

  useEffect(() => {
    formikRef.current?.setFieldValue("date_sent", formData?.date_sent);
    formikRef.current?.setFieldValue("date_return", formData?.date_return);
  }, [formData?.date_return, formData?.date_sent]);

  useEffect(() => {
    clearTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainFormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, helpers) => {
          const { direction, city_from, city_to, date_sent, date_return } =
            values;
          setFormData({
            ...values,
            adult: adultCount,
            children: childrenCount,
          });

          direction === "oneway"
            ? navigate({
                pathname: `/${i18n.language}/buses`,
                search: createSearchParams({
                  direction: direction,
                  city_from: city_from,
                  city_to: city_to,
                  date_sent: date_sent,
                }).toString(),
              })
            : navigate(
                {
                  pathname: `/${i18n.language}/buses`,
                  search: createSearchParams({
                    direction: direction,
                    city_from: city_from,
                    city_to: city_to,
                    date_sent: date_sent,
                    date_return: date_return,
                  }).toString(),
                },
                { replace: true }
              );
        }}
        innerRef={(ref) => (formikRef.current = ref)}
      >
        {({ values: { direction, city_to, city_from }, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={{ xs: 1, md: 1}}>
                <Grid item xs={12}>
                  <DirectionRadioBtn name="direction" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={{ xs: 2, md: 2 }}>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Grid container spacing={{ xs: 2, md: 0.5 }}>
                        <Grid item xs={12} sm={12} lg={6} md={6}>
                          <RelativAutocompleteWrapper>
                            <CityPicker
                              name="city_from"
                              label={t("city_from")}
                            />

                            <SwapWrapper
                              onClick={(e: MouseEvent<HTMLDivElement>) => {
                                e.stopPropagation();
                                setFieldValue("city_to", city_from);
                                setFieldValue("city_from", city_to);
                              }}
                            >
                              <SwapBtn />
                            </SwapWrapper>
                          </RelativAutocompleteWrapper>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={6} md={6}>
                          <CityPicker name="city_to" label={t("city_to")} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Grid container spacing={2}>
                        {direction === "oneway" && (
                          <Grid item xs={12} sm={12}>
                            <DatePicker
                              name="date_sent"
                              label={t("date_sent")}
                            />
                          </Grid>
                        )}
                        {direction === "andBack" && (
                          <Grid item xs={12} sm={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <DatePicker
                                  name="date_sent"
                                  backPicker
                                  label={t("date_sent")}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <DatePicker
                                  name="date_return"
                                  label={t("date_return")}
                                  mindate
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <PassengersCount />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <StyledButton
                        type="submit"
                        color="info"
                        variant="contained"
                      >
                        {t("searchButton")}
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{textAlign:"center"}}><BackDiscount/></Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </MainFormWrapper>
  );
}
