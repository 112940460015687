import { Box } from "@mui/material";
import lap from "./images/lap@2x.png";

export default function FooterCityImage() {
  return (
    <Box sx={{ width: "100%", height: "auto", background: "inherits" }}>
      <img
        src={lap}
        alt="footer"
        width={1440}
        height={286}
        style={{
          width: "100vw",
          height: "auto",
          display: "block",
          background: "inherits",
        }}
      />
    </Box>
  );
}
