import { ContactItem } from "../constans";
import {
  ContactsItemWrapper,
  ContactsListItem,
  ContactsListLink,
} from "../style";

interface IContactsItemProps {
  handleCloseUserMenu: () => void;
  contact: ContactItem;
}

export default function ContactsItem({
  handleCloseUserMenu,
  contact,
}: IContactsItemProps) {
  return (
    <ContactsListItem>
      {typeof contact.icon !== "string" ? (
        <ContactsItemWrapper>{contact.icon}</ContactsItemWrapper>
      ) : (
        <ContactsItemWrapper>
          <img
            src={contact.icon}
            alt={`Contact icon for ${contact.label}`}
            width={20}
            height={20}
          />
        </ContactsItemWrapper>
      )}
      <ContactsListLink
        to={contact.link}
        target="_blank"
        onClick={() => handleCloseUserMenu()}
      >
        {contact.label}
      </ContactsListLink>
    </ContactsListItem>
  );
}
