import { Box, Grid, Popover, TextField, Divider } from "@mui/material";

import {
  AddIconGrey,
  CenteredCountItems,
  ContainerSubTitle,
  ContainerTitle,
  ContentContainer,
  CountButton,
  CountContainer,
  CountNumber,
  PassengersCountTitle,
  PopoverContetnWrapper,
  RemoveIconGrey,
} from "./style";
import { useState } from "react";
import { useSearchForm } from "features/searchform/useShopForm";
import { useTranslation } from "react-i18next";

export default function PassengersCount() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();

  const {
    incrementPassengers,
    decrementPassengers,
    adultCount,
    childrenCount,
  } = useSearchForm();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <TextField
        fullWidth
        name="PassengersPopover"
        focused={!!anchorEl}
        inputProps={{
          readOnly: true,
        }}
        autoComplete="off"
        onFocus={handleClick}
        label={t("passengersCount")}
        value={` ${adultCount} ${adultCount > 2 ? t("adults") : t("adult")}${
          childrenCount > 0
            ? `, ${childrenCount} ${
                childrenCount > 2 ? t("children") : t("child")
              }`
            : ""
        }`}
        size="small"
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{ paper: { style: { width: 320 } } }}
      >
        <PopoverContetnWrapper>
          <Grid item xs={12}>
            <PassengersCountTitle>
              {t("numberOfPassengers")}
            </PassengersCountTitle>
            <ContentContainer container>
              <Grid item xs={6}>
                <ContainerTitle>{t("adultsTitle")}</ContainerTitle>
              </Grid>
              <Grid item xs={6}>
                <CountContainer>
                  <CenteredCountItems item xs={4}>
                    <CountButton
                      onClick={() => decrementPassengers("adult")}
                      disabled={adultCount <= 1}
                      size="small"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <RemoveIconGrey />
                    </CountButton>
                  </CenteredCountItems>
                  <CenteredCountItems item xs={4}>
                    <CountNumber>{adultCount}</CountNumber>
                  </CenteredCountItems>
                  <CenteredCountItems item xs={4}>
                    <CountButton
                      onClick={() => incrementPassengers("adult")}
                      disabled={adultCount >= 10}
                      size="small"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <AddIconGrey />
                    </CountButton>
                  </CenteredCountItems>
                </CountContainer>
              </Grid>
            </ContentContainer>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <ContentContainer container>
              <Grid item xs={6}>
                <Box>
                  <ContainerTitle>{t("childrenTitle")}</ContainerTitle>
                  <ContainerSubTitle>{t("upTo12Years")}</ContainerSubTitle>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <CountContainer>
                  <CenteredCountItems item xs={4}>
                    <CountButton
                      onClick={() => decrementPassengers("children")}
                      disabled={childrenCount === 0}
                      size="small"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <RemoveIconGrey />
                    </CountButton>
                  </CenteredCountItems>
                  <CenteredCountItems item xs={4}>
                    <CountNumber>{childrenCount}</CountNumber>
                  </CenteredCountItems>
                  <CenteredCountItems item xs={4}>
                    <CountButton
                      onClick={() => incrementPassengers("children")}
                      disabled={childrenCount >= 10}
                      size="small"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <AddIconGrey />
                    </CountButton>
                  </CenteredCountItems>
                </CountContainer>
              </Grid>
            </ContentContainer>
          </Grid>
        </PopoverContetnWrapper>
      </Popover>
    </Box>
  );
}
